import React, { useLayoutEffect, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/Logo.svg";
import Call from "../assets/Contact us.svg";
import Download from "../assets/image/img3.png";
import "./Header.scss";
import "./header.css";

import HeaderNavItem from "./HeaderNavItem";
import _ from "lodash";
import { useWindowSize } from "react-use";
import { getHeaderProducts } from "../data/productsData";
import { downloadsvg } from "../assets";
const body = document.querySelector("body");

const HeaderNew = ({ stickyProp }) => {
  const { width } = useWindowSize();
  const { pathname: locationPathname } = useLocation();
  const [navActiveIndex, setNavActiveIndex] = useState(null);
  const [isMenuActive, setIsMenuActive] = useState(false);
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(1090);
  const headerClasses = `header ${sticky && check ? "sticky" : "sticky"}`;

  function useStickyHeader(offset = 0) {
    const [stick, setStick] = useState(false);

    const handleScroll = () => {
      setStick(window.scrollY > offset);
    };

    useLayoutEffect(() => {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    });

    return stick;
  }

  const brandsDataArr = [
    {
      dropDownTitle: "Tubes & Pipes",
      dropDownLink: "/products/tube-and-pipes",
      // subLinks: [
      //   {
      //     title: "- Copper Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- 90/10 Copper Nickel Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- 70/30 Copper Nickel Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Admiralty Brass Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Aluminium Bross Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Aluminium Bronze Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- 95/5 Copper Nickel",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Brasses",
      //     slug: "product-detail",
      //   },
      // ],
      subLinks: getHeaderProducts(1),
    },
    {
      dropDownTitle: "Speciality & Finned Tube",
      dropDownLink: "/products/speciality-and-finned-tubes",
      // subLinks: [
      //   {
      //     title: "- Finned Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- U Bend Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Machined Components",
      //     slug: "product-detail",
      //   },
      // ],
      subLinks: getHeaderProducts(2),
    },
    {
      dropDownTitle: "Cast, Extruded",
      dropDownLink: "/products/cast-extruded-and-drawn-semis",
      subLinks: getHeaderProducts(3),
      // subLinks: [
      //   {
      //     title: "- Level Wound Coils",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Finned tube",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Pancake Coils",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Admiralty Brass Tubes",
      //     slug: "product-detail",
      //   },
      //   {
      //     title: "- Admiralty Brass Tubes",
      //     slug: "product-detail",
      //   },
      // ],
    },
    // {
    //   dropDownTitle: "Wires",
    //   dropDownLink: "products",
    //   subLinks: [],
    // },
  ];

  // const brandsSlugsList = brandsDataArr?.length
  //   ? brandsDataArr.map((brandData) => {
  //       return {
  //         navTitle: brandData.title,
  //         navLink: `/our-brand/${brandData.slug}`,
  //       };
  //     })
  //   : null;

  const newBrandSlugsList = brandsDataArr?.length
    ? brandsDataArr.map((data, i) => ({
        dropDowntitle: data?.dropDownTitle,
        dropDownLink: data?.dropDownLink,
        activeId: i,
        linkArr: data?.subLinks?.map(({ title, slug }) => ({
          navTitle: title,
          navLink: slug,
        })),
      }))
    : null;

  //duplicating subcategories of disclosures-under-regulation-46-of-the-lodr in  disclosures-under-regulation-62-of-the-lodr

  // let tempCategoryIndex = _.findIndex(investorsMenuData, (o) => {
  //   return o.slug === "disclosures-under-regulation-46-of-the-lodr";
  // });

  // investorsMenuData.splice(tempCategoryIndex + 1, 0, {
  //   name: "Disclosures under Regulation 62 of the LODR",
  //   slug: "disclosures-under-regulation-62-of-the-lodr",
  //   subcategories: investorsMenuData[tempCategoryIndex].subcategories,
  // });

  // const investorsSlugsList = [
  //   ...investorsMenuData?.map(({ slug: mainCatSlug, name, subcategories }) => {
  //     return {
  //       dropDowntitle: name,
  //       linkArr: subcategories
  //         .filter(({ showInMenu }) => showInMenu === true)
  //         .map(({ title, slug, showInMenu }) => {
  //           return {
  //             navTitle: title,
  //             navLink: `/investor/${mainCatSlug}/${slug}`,
  //           };
  //         }),
  //     };
  //   }),

  //   {
  //     dropDowntitle: "Investor Contact Details",
  //     linkArr: [],
  //     dropDownLink: "/investor-contact",
  //   },
  // ];
  // const oneLevelInvestorsSlugsList = [
  //   ...investorsMenuData?.map(({ slug: mainCatSlug, name, subcategories }) => {
  //     return {
  //       navTitle: name,
  //       navLink: `/investor/${mainCatSlug}`,
  //     };
  //   }),
  //   {
  //     navTitle: "Investor Contact Details",
  //     navLink: "/investor-contact",
  //   },
  // ];

  // const csrSlugsList = susMenuData
  //   ?.filter(({ category }) => category.toLowerCase() === "csr")
  //   .map(({ slug, title }) => ({
  //     navTitle: title,
  //     navLink: `/beyond-business/csr/${slug}`,
  //   }));
  // const governaceCategoryList = governaceCategoryData?.length
  //   ? governaceCategoryData?.map(({ slug, title }) => ({
  //       navTitle: title,
  //       navLink: `/our-company/${slug}`,
  //     }))
  //   : [];

  // const esgSlugsList = susMenuData;
  // ?.filter(({ category }) => category.toLowerCase() === "esg")
  // .map(({ slug, title }) => ({
  //   navTitle: title,
  //   navLink: `/beyond-business/esg/${slug}`,
  // }));

  const navDataArr = [
    {
      mainTitle: "About Us",
      // navLink: "/about",
      dropDownLevel: 1,
      dropDownList: [
        {
          navTitle: "Our Journey",
          navLink: "/about",
        },
        {
          navTitle: "Gallery",
          navLink: "/gallery",
        },
        {
          navTitle: "Financial",
          navLink: "/financial",
        },
        {
          navTitle: "Awards & Recognition",
          navLink: "/awards-&-recognition",
        },
      ],
    },
    {
      mainTitle: "Client",
      dropDownLevel: 1,
      dropDownList: [
        {
          navTitle: "Indigenous",
          navLink: "/indigenous",
        },
        {
          navTitle: "Overseas Presence",
          navLink: "/overseas-presence",
        },
      ],
    },
    {
      mainTitle: "Quality",
      dropDownLevel: 1,
      dropDownList: [
        {
          navTitle: "Quality Overview",
          navLink: "/quality-overview",
        },
        {
          navTitle: "Certifications",
          navLink: "/certifications",
        },
        {
          navTitle: "Vendor Approval",
          navLink: "/vendor-approval",
        },
        {
          navTitle: "Packaging & Dispatch",
          navLink: "/packaging-&-dispatch",
        },
        {
          navTitle: "Testing Facilities",
          navLink: "/testing-facilities",
        },
      ],
    },
    {
      mainTitle: "Product",
      dropDownLevel: 2,
      dropDownList: newBrandSlugsList,
    },
    {
      mainTitle: "Applications",
      mainLink: "/applications",
    },
    {
      mainTitle: "Manufacturing Process",
      mainLink: "/manufacturing-process",
    },
    {
      mainTitle: "Contact Us",
      mainLink: "/contact-us",
    },
  ];

  useEffect(() => {
    body.style.overflow = "auto";
    setIsMenuActive(false);
  }, [locationPathname]);

  const handleHamburgerClick = () => {
    setIsMenuActive(!isMenuActive);
    if (!isMenuActive) {
      // Disable scroll
      body.style.overflow = "hidden";
    } else {
      // Enable scroll
      body.style.overflow = "auto";
    }
  };

  const handleNavIndex = (i) => {
    if (navActiveIndex === i) {
      setNavActiveIndex(null);
    } else {
      setNavActiveIndex(i);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [navlogo, setNavlogo] = useState(false);

  const changelogo = () => {
    if (window.scrollY >= 200) {
      setNavlogo(true);
    } else {
      setNavlogo(false);
    }
  };
  window.addEventListener("scroll", changelogo);

  return (
    <>
      <header id="header" className={headerClasses}>
        <a className="logo_link">
          <div className="logo_wrapper">
            <a href="/">
              <img
                src={logo}
                alt="Multi Metal logo"
                className="multimetal_logo"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              />
            </a>
          </div>
        </a>
        <nav className="nav_links_wrapper">
          <div
            className={`nav_line2_wrapper_flex_wrapper ${
              isMenuActive ? "active" : ""
            }`}
          >
            {/* <li className="search_nav_li" style={{ listStyle: "none" }}>
              <div className="search-nav">
                <input type="text" placeholder="Search for...." />
                <div className="serach_icon">
                  <button>
                    <img src={Search} />
                  </button>
                </div>
              </div>
            </li> */}
            <div className="logo_wrapper_mobile">
              <Link to="/">
                <img
                  src={logo}
                  alt="Multi Metal logo"
                  className="multimetal_logo"
                />
              </Link>
            </div>
            <ul className="nav_line2_wrapper_flex custom-li">
              {navDataArr.map((navData, i) => (
                <HeaderNavItem
                  navData={navData}
                  key={i}
                  arrIndex={i}
                  handleNavIndex={handleNavIndex}
                  navActiveIndex={navActiveIndex}
                />
              ))}
              {/* <li className="nav_item contact_us_mob">
                <Link className="nav_link" to="contact-us">
                  Contact Us
                </Link>
              </li> */}
              <li className="nav_item download_desktop">
                <Link to="contact-us">
                  <div
                    className="nav_link"
                    style={{ color: "#fff", display: "flex" }}
                  >
                    <img
                      src={Call}
                      alt=""
                      // className="download_icon"
                      style={{
                        margin: "0 1em 0 0 ",
                        width: "100%",
                        maxWidth: "3em",
                        marginRight: "0",
                      }}
                    />
                  </div>
                </Link>
              </li>
              <li
                className="nav_item download_desktop"
                style={{ paddingRight: "4em", paddingLeft: "0" }}
              >
                <a
                  href="https://www.multimetals.in/brochure.pdf"
                  target="_blank"
                >
                  <div
                    className="nav_link"
                    style={{ color: "#fff", display: "flex" }}
                  >
                    <img
                      src={downloadsvg}
                      alt=""
                      className="download_icon"
                      style={{ margin: "0 1em 0 0 " }}
                    />
                    Download
                    <br />
                    Brochure
                  </div>
                </a>
              </li>
              {/* <li className="nav_item" style={{ margin: "0", padding: "0" }}>
                <div className="download-desktop-btn">
                  <div className="down_brohcer">
                    <div className="download_icon">
                      <button>
                        <img src={Download} />
                      </button>
                    </div>
                    <div className="brochure" style={{color: "#fff" , margin: "0 0 0 1em"}}>Download Brochure</div>
                  </div>
                </div>
              </li> */}
            </ul>
            <li
              className="download-mobile-btn"
              style={{
                position: "absolute",
                bottom: "3em",
                left: "15px",
                listStyle: "none",
              }}
            >
              <div className="down_brohcer">
                <div className="download_icon">
                  <button>
                    <img src={Download} />
                  </button>
                </div>
                <p style={{ marginLeft: "5px" }}>Download Brochure</p>
              </div>
            </li>
          </div>
        </nav>

        {/* mobile UI start */}
        <div
          className={`hamburger_lines ${isMenuActive ? "active" : ""}`}
          onClick={handleHamburgerClick}
        >
          <div className="line line1"></div>
          <div className="line line2"></div>
          <div className="line line3"></div>
        </div>
      </header>
    </>
  );
};

export default HeaderNew;
