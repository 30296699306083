import React, { useRef, useEffect } from 'react'

const ViewportWatcher = ({ children, className }) => {
  const markRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.querySelectorAll('a').forEach((anchor) => {
              anchor.classList.add(className);
            });
          } else {
            entry.target.querySelectorAll('a').forEach((anchor) => {
              anchor.classList.remove(className);
            });
          }
        });
      },
      { threshold: 0.5 }
    );

    if (markRef.current) {
      observer.observe(markRef.current);
    }

    return () => {
      if (markRef.current) {
        observer.unobserve(markRef.current);
      }
    };
  }, [className]);


  return <div ref={markRef}>{children}</div>;

}

export default ViewportWatcher