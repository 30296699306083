import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { billetHeater } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Billetheater = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="ELECTRIC INDUCTION BILLET HEATER"
          link2="/electric-induction-billet-heater"
        /> */}
        <section id="scrolltop" className="billet_heater_sec1">
          <div className="row billet_heater_container">
            <div className="col-md-5 billet_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="billet_heading">
                  ELECTRIC INDUCTION BILLET HEATER
                </h2>
                <p className="billet_details">
                  Before the tubes can undergo further processing, they are
                  heated in an electric induction billet heater. This
                  specialized heating equipment utilizes electromagnetic
                  induction to generate heat within the tubes. The electric
                  induction billet heater ensures that the tubes reach the
                  required temperature for subsequent processes such as drawing
                  and extrusion. The precise and controlled heating provided by
                  the induction heater helps to optimize the material's
                  properties, making it more suitable for shaping and forming.
                </p>
              </motion.div>
            </div>
            <div className="col-md-7 billet_content">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={billetHeater}
                  alt="Billet Heater"
                  className="billet_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Billetheater;
