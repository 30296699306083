import React, { useState, useEffect, useRef } from "react";
import Divider from "@mui/material/Divider";
import "./home.scss";
import {
  awardsData,
  certificationsData,
  crsData,
  globalPresenceData,
  heroData,
  ourStoryData,
  productData,
  trustedPartnersData,
} from "../data";
import { Button } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";
import CsrBg from "../assets/home/csr-banner.jpg";
import mobileCsrBg from "../assets/home/csr-bg.png";
import mobileAwardsBg from "../assets/home/awards-bg.png";
import csrImg from "../assets/home/csrImg.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, EffectFlip, Pagination } from "swiper";
import Award from "../assets/home/awards-banner.png";
import Presense from "../assets/image/img19.png";
import { motion } from "framer-motion";
import { useIsInViewport } from "../hooks/useIsInViewport";
import LocomotiveScroll from "locomotive-scroll";
import HTMLRenderer from "react-html-renderer";
import { useWindowSize } from "react-use";
import { Controller } from "swiper";
import {
  fixedContainer,
  pathCircleVariants,
  textVariants,
} from "../Header/Motion";
import Msg from "../assets/mail.png";
import Video from "../assets/video.mp4";
import { TfiAngleDown, TfiAngleUp } from "react-icons/tfi";
import { Thumbs, EffectCoverflow } from "swiper";
import PreLoader from "../loader/preloader";

import Footer from "../Footer/Footer";
import ViewportWatcher from "../common/ViewportWatcher";
import CTASvg from "../common/CTASvg";
import { Link } from "react-router-dom";
import AnimatedCountUp from "../common/AnimatedCountUp";
import { isTablet, isSafari } from 'react-device-detect';
const imagesLoaded = require("imagesloaded");
const countriesArr = [
  "Algeria",
  "Australia",
  "Austria",
  "Bahrain",
  "Bangladesh",
  "Brazil",
  "Belgium",
  "Canada",
  "Chile",
  "Czech Republic",
  "Denmark",
  "Egypt",
  "Finland",
  "Germany",
  "Greece",
  "Italy",
  "Jordan",
  "Kuwait",
  "Malaysia",
  "Mexico",
  "Morocco",
  "Netherlands",
  "New Zealand",
  "Oman",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Saudi Arabia",
  "Singapore",
  "Slovakia",
  "South Africa",
  "Spain",
  "Sweden",
  "Thailand",
  "Trinidad & Tobago",
  "UAE",
  "UK",
  "USA",
  "Venezuela",
  "Zambia",
];

const Home = () => {
  const { width } = useWindowSize();
  const [data, setData] = useState(heroData);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [activeSlider, setActiveSlider] = useState(0);
  const [sliderLength, setSliderLength] = useState(null);
  const { targetRef, isInViewport } = useIsInViewport();
  const scrollRef = useRef(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);
  const [firstSwiper, setFirstSwiper] = useState(null);
  const [secondSwiper, setSecondSwiper] = useState(null);

  // show marks
  const [showLocation, setShowLocation] = useState("");

  const toggleShowLocation = (location) => {
    setShowLocation(location === showLocation ? "" : location);
  };

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = (mark) => {
    setIsHovered(mark);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  // console.log(thumbsSwiper.slides.length);

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `   <span class=${className}>
            <svg width='26' height='26' viewBox='0 0 28 28'></svg>
            <div class="slider_line"></div>
            <span class="slider_indicator_count_">0${index + 1}</span>
          </span>`;
    },
  };

  const handleButtonClick = (index) => {
    thumbsSwiper.slideTo(index);
  };

  const options = ["Spanish 🇪🇸", "Portugese 🇵🇹", "Italian 🇪🇸", "French 🇫🇷"];

  useEffect(() => {
    if (isInViewport) {
      const timeout = setTimeout(() => {
        const letterElements = document.querySelectorAll(".letter");
        const regTextElements = document.querySelectorAll(".reg-text");
        letterElements.forEach((element) => {
          element.classList.add("loaded");
        });

        regTextElements.forEach((element) => {
          element.classList.add("loaded");
        });
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [isInViewport]);

  useEffect(() => {
    const handleScroll = () => {
      setShowLocation("");
      console.log("scroll");
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });

    const resizeObserver = new ResizeObserver(() => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);


  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >

        <div data-scroll-section>
          <section
            id="hero_section"
            style={{ lineHeight: "0", overflow: "hidden" }}
          >
            <figure id="scrolltop" class="bg figure">
              {/* <video
                class="img-cover"
                src={data.videoHome}
                loop
                autoPlay
                muted
                playsInline
                preload="auto"
              ></video> */}
              <video
                class="img-cover"
                loop
                autoPlay
                muted
                playsInline
                preload="auto" >
                <source src={data.videoHome} />
                Your browser does not support the video tag.
              </video>

            </figure>
            {/* <div className="select">
    <PreLoader />
      <section id="hero_section" style={{ lineHeight: "0" }}>
        <figure class="bg figure">
          <video
            class="img-cover"
            src={Video}
            loop
            autoPlay
            muted
            playsInline
          ></video>
        </figure>
        {/* <div className="select">
          <div
            className={`select__header ${isOpen ? "open" : ""}`}
            onClick={toggleSelect}
          >
            <span>{selectedOption || "SELECT LANGUAGE"}</span>
            {isOpen ? (
              <TfiAngleUp className="arrow" />
            ) : (
              <TfiAngleDown className="arrow" />
            )}
          </div>
          {isOpen && (
            <ul className="select__options">
              {options.map((option) => (
                <li
                  key={option}
                  className={`select__option ${
                    selectedOption === option ? "selected" : ""
                  }`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div> */}
            <div className="bg">
              <section
                className="hero-content-d"
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.5"
              >
                <TypeAnimation
                  sequence={[
                    data?.content,
                    5000,
                    data?.content,
                    5000,
                    data?.content,
                    5000,
                    data?.content,
                    5000,
                  ]}
                  speed={1}
                  deletionSpeed={99}
                  repeat={Infinity}
                />
                <div className="hero-btn-d">
                  <Link to="/about#corporate">
                    <motion.Button
                      className="learn_morn_btn"
                      variants={textVariants("right", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      Corporate Video
                      <CTASvg />
                    </motion.Button>
                  </Link>
                  <Link to="/contact-us">
                    <motion.div
                      class="cta"
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <Button>
                        Contact Us
                        <CTASvg />
                      </Button>
                    </motion.div>
                  </Link>
                </div>
              </section>
            </div>

            {/* <div className="scroll-down-d">
            <div class="spinner scroll-down">
              {" "}
              <a style={{ cursor: "pointer" }} href="#ourstory"
                data-scroll-to class="animate"></a>{" "}
            </div>
          </div> */}
            <div className="scroll-down-d" style={{ zIndex: "5" }}>
              <div class="spinner scroll-down">
                <a
                  style={{ cursor: "pointer" }}
                  href="#ourstory"
                  data-scroll-to
                  class="animate"
                ></a>
              </div>
            </div>
          </section>

          <section
            id="ourstory"
            className="our-story-s"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className="story_sec_one">
              <div className="row story-d">
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 left-d p-0"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="0.5"
                >
                  <div className="image-d">
                    <motion.div
                      className="first-img"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src={ourStoryData.image1}
                        alt="image"
                        className=""
                        height={764}
                      />
                    </motion.div>
                    {/* <motion.div
                      className="second-img"
                      variants={textVariants("down", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img src={ourStoryData.image2} alt="image" className="" />
                    </motion.div> */}
                  </div>
                  <div className="hero-btn-d mobile_">
                    <Link to="/about">
                      <motion.div
                        class="cta"
                        variants={textVariants("down", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Button>
                          Our Story
                          <CTASvg />
                        </Button>
                      </motion.div>
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-6 col-md-6 col-sm-12 col-12 right-d p-0"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="2.5"
                >
                  <div className="content-d">
                    <motion.div
                      className="heading-d"
                      variants={textVariants("down", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h1>{ourStoryData.title}</h1>
                    </motion.div>
                    <div className="content mt-3">
                      <motion.p
                        className="section_one_content"
                        variants={textVariants("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <HTMLRenderer html={ourStoryData.content} />
                      </motion.p>
                      <div className="d-flex align-items-center flex-wrap main_section_one">
                        {ourStoryData.quality?.map((data, index) => {
                          return (
                            <>
                              <motion.div
                                className="count-up"
                                variants={textVariants("down", 0.2)}
                                initial="hidden"
                                whileInView="show"
                                viewport={{ once: true, amount: 0.1 }}
                                transition={{ duration: 0.5 }}
                              >
                                <h1>
                                  <AnimatedCountUp end={data.count} /><span className="mb-2">+</span>
                                </h1>
                                <p>{data.text}</p>
                              </motion.div>
                              {(index + 1) % 2 === 1 && <div className="v-line"></div>}

                            </>
                          )
                        })}
                      </div>

                      <div className="hero-btn-d">
                        <Link to="/about">
                          <motion.div
                            class="cta"
                            variants={textVariants("down", 0.2)}
                            initial="hidden"
                            whileInView="show"
                            viewport={{ once: true, amount: 0.1 }}
                            transition={{ duration: 0.5 }}
                          >
                            <Button>
                              Our Story
                              <CTASvg />
                            </Button>
                          </motion.div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="our_product-s"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className="product_slider">
              <div className="product_heading">
                <motion.h1
                  variants={textVariants("down", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  OUR PRODUCTS
                </motion.h1>
              </div>
              <Swiper
                effect={"slide"}
                longSwipesRatio={0.01}
                slidesPerView={1}
                loop={true}
                speed={2000}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                noSwipingClass="swiper-slide"
                className="mySwiper product-main-slider"
                pagination={pagination}
                modules={[Controller, Pagination, Autoplay]}
                onSwiper={setSecondSwiper}
                controller={{ control: firstSwiper }}
              >
                {productData?.map((data) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className="product-d">
                          <div
                            className="product-left-d"
                            data-scroll
                            data-scroll-direction="vertical"
                            data-scroll-class="appear"
                            data-scroll-repeat="true"
                            data-scroll-speed="2.5"
                          >
                            {/* <div
                            className="product_heading"
                          // variants={textVariants("down", 0.2)}
                          // initial="hidden"
                          // whileInView="show"
                          // viewport={{ once: true, amount: 0.1 }}
                          // transition={{ duration: 0.5 }}
                          >
                            <h1>OUR PRODUCTS</h1>
                          </div> */}

                            <div className="product-content-d">
                              <div>
                                <motion.h1
                                  className="heading_"
                                  variants={textVariants("left", 0.5)}
                                  initial="hidden"
                                  whileInView="show"
                                  viewport={{ once: false, amount: 0.1 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <HTMLRenderer html={data.title} />
                                </motion.h1>
                                <motion.p
                                  className="content_"
                                  variants={textVariants("left", 0.5)}
                                  initial="hidden"
                                  whileInView="show"
                                  viewport={{ once: false, amount: 0.1 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  {data.content}
                                </motion.p>

                                <div className="hero-btn-d">
                                  <Link to={`/products/${data.slug}`}>
                                    <motion.div
                                      class="cta"
                                      variants={textVariants("left", 0.5)}
                                      initial="hidden"
                                      whileInView="show"
                                      viewport={{ once: false, amount: 0.1 }}
                                      transition={{ duration: 0.5 }}
                                    >
                                      <Button>
                                        View products
                                        <CTASvg />
                                      </Button>
                                    </motion.div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="product-right-d"
                            data-scroll
                            data-scroll-direction="vertical"
                            data-scroll-class="appear"
                            data-scroll-repeat="true"
                            data-scroll-speed="0.5"
                          >
                            <div class="product">
                              <div className="video-section">
                                <motion.div
                                  className="slider-video"
                                  variants={textVariants("right", 0.5)}
                                  initial="hidden"
                                  whileInView="show"
                                  viewport={{ once: false, amount: 0.1 }}
                                  transition={{ duration: 0.5 }}
                                >
                                  <motion.img
                                    className="video_"
                                    variants={textVariants("right", 0.5)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: false, amount: 0.1 }}
                                    transition={{ duration: 0.5 }}
                                    src={data.image1}
                                    height={513}
                                  />
                                </motion.div>
                              </div>
                              <div className="hero-btn-d mobile_">
                                <Link to={`/products/${data.slug}`}>
                                  <motion.div
                                    class="cta"
                                    variants={textVariants("left", 0.5)}
                                    initial="hidden"
                                    whileInView="show"
                                    viewport={{ once: false, amount: 0.1 }}
                                    transition={{ duration: 0.5 }}
                                  >
                                    <Button>
                                      View products
                                      <CTASvg />
                                    </Button>
                                  </motion.div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              </Swiper>
            </div>
            {/* <div className="product">
              <Swiper
                className="product-slider"
                direction={"vertical"}
                effect={"slide"}
                longSwipesRatio={0.01}
                slidesPerView={1}
                loop={true}
                speed={2000}
                noSwiping={true}
                noSwipingClass="swiper-slide"
                modules={[Controller]}
                onSwiper={setFirstSwiper}
                controller={{ control: secondSwiper }}
              >
                {productData
                  ?.map((data) => data.image2)
                  .map((image) => {
                    return (
                      <SwiperSlide>
                        <motion.img
                          className="vert-move"
                          src={image}
                          variants={textVariants("down", 0.4)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: false, amount: 0.1 }}
                          transition={{ duration: 0.8 }}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div> */}
          </section>

          <section
            className="presence-s"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className="presence-s_bg">
              <div className=""
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                <motion.div
                  className="presence-s_heading"
                  variants={textVariants("down", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <h1>GLOBAL PRESENCE</h1>
                </motion.div>
                <motion.div
                  variants={textVariants("down", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                  className="map presence_img_sec"
                >
                  <img src={Presense} style={{ width: "100%" }} />
                  <div class="map-pins">
                    <ViewportWatcher className="bounceInDown">
                      {globalPresenceData.locationMarks.map((mark) => {
                        return (
                          <>
                            <div>
                              <a
                                onMouseEnter={() => handleMouseOver(mark.id)}
                                onMouseLeave={() => handleMouseOut()}
                                
                                style={{
                                  left: `${mark.left}vw`,
                                  top: `${mark.top}%`,
                                  animationDelay: `${mark.delay}`,
                                  cursor: "pointer",
                                }}
                                class="map-location-marker animated"
                                data-map-title="San Francisco"
                                data-map-link="san-francisco"
                                data-map-image="san-francisco.jpg"
                                data-job-listings="https://westernunion.taleo.net/careersection/10045/jobsearch.ftl?&src=CWS-10230"
                              ></a>

                              {/* {showLocation == mark.name && <> */}
                              <svg
                                className={`arrowSvg ${isHovered == mark.id ? "d-block" : "d-none"
                                  }`}
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  color: "#483F3C",
                                  left: `${mark.left - 0.3}vw`,
                                  top: `${mark.top - 2.5}%`,
                                  zIndex: "2",
                                }}
                              >
                                <path d="m4.431 12.822 13 9A1 1 0 0 0 19 21V3a1 1 0 0 0-1.569-.823l-13 9a1.003 1.003 0 0 0 0 1.645z"></path>
                              </svg>
                              <div
                                className={`locationBx ${isHovered == mark.id ? "d-flex" : "d-none"
                                  }`}
                                style={{
                                  left: `${mark.left + 1}vw`,
                                  top: `${mark.top - 4}%`,
                                  zIndex: "9",
                                }}
                              >
                                <h1 className="m-0 text-center">{mark.name}</h1>
                              </div>
                              {/* </>} */}
                            </div>
                          </>
                        );
                      })}
                    </ViewportWatcher>
                  </div>
                </motion.div>

                <div className="presence-s_section_c">
                  <div className="presence-s-count_sec  col-lg-6 col-md-6 col-sm-12 col-12">
                    <motion.div
                      className="count-up"
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="text_container" ref={targetRef}>
                        <span
                          className="reg-text align-items-center"
                          style={{ gap: "0.32291666666665vw" }}
                        >
                          <h1>
                            <AnimatedCountUp end={globalPresenceData.count} />
                            <span style={{ marginTop: "-0.65vw" }}>+</span>
                          </h1>{" "}
                          <p className="text-left">
                            <HTMLRenderer html={globalPresenceData.title} />
                          </p>
                        </span>
                      </div>
                    </motion.div>
                  </div>

                  <Divider orientation="vertical" />
                  <motion.div
                    className="presence-s_content_section col-lg-6 col-md-6 col-sm-12 col-12"
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p>{globalPresenceData.content}</p>
                  </motion.div>
                </div>

                <div className="presence-s_two">
                  <motion.div
                    className="presence-s_two_heading col-lg-5 col-md-5 col-sm-12 col-12"
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h1>
                      <HTMLRenderer html={trustedPartnersData.title} />
                    </h1>
                  </motion.div>
                  <motion.div
                    className="presence-s_two_content col-lg-6 col-md-6 col-sm-12 col-12"
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p>{trustedPartnersData.content}</p>
                  </motion.div>
                </div>
                <div className="presence-s_three">
                  <motion.div
                    className="presence-s_three_img"
                    variants={textVariants("down", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {trustedPartnersData.partners?.map((partner, index) => {
                      console.log(partner);
                      return (
                        <>
                          <Swiper
                            direction="vertical"
                            loop={true}
                            autoplay={{
                              delay: 0.5,
                            }}
                            effect={"flip"}
                            noSwiping={true}
                            noSwipingClass="swiper-slide"
                            speed={3000}
                            modules={[Autoplay, EffectFlip]}
                            className="mySwiper trusted-partners"
                            slidesPerView={1}
                          >
                            {partner.images?.map((image) => {
                              return (
                                <SwiperSlide>
                                  <img src={image.image} />
                                </SwiperSlide>
                              );
                            })}
                            {/* <SwiperSlide>
                            <img src={image.image2} />
                          </SwiperSlide> */}
                          </Swiper>
                          {index != trustedPartnersData.partners.length - 1 && (
                            <Divider orientation="vertical" />
                          )}
                        </>
                      );
                    })}
                  </motion.div>
                </div>
              </div>
            </div>
          </section>

          <section
            className={`csr-programs-s  ${isSafari ? 'ios-safari' : ''}`}
            // data-scroll-section
            style={{
              backgroundImage: `url(${width > 767 ? CsrBg : mobileCsrBg})`,
              overflow: "hidden",
            }}
          >
            <div className="csr-programs-d">
              <div className="csr-programs row m-0">
                <motion.div
                  className="csr-img-d col-lg-6 col-md-6 col-sm-12 col-12 p-0"
                  variants={textVariants("down", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src={crsData.image}
                    alt="csr-image"
                    width={705}
                    height={820}
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.5"
                  />
                </motion.div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 p-0">
                  <div
                    className="csr-programs-d-right "
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="2.5"
                  >
                    <motion.h1
                      className="heading"
                      variants={textVariants("down", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      {crsData.title}
                    </motion.h1>
                    <div className="content">
                      <motion.p
                        className="text-white csr-programs-d_ph"
                        variants={textVariants("down", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        {crsData.content}
                      </motion.p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="certificate-s"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className=""
              data-scroll
              data-scroll-direction="vertical"
              data-scroll-class="appear"
              data-scroll-repeat="true"
              data-scroll-speed="0.25"
            >
              <motion.div
                className="certificate-s_heading"
                variants={textVariants("down", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h1>{certificationsData.title}</h1>
              </motion.div>

              <Swiper
                loop={true}
                autoplay={{
                  delay: 0.5,
                }}
                noSwiping={true}
                noSwipingClass="swiper-slide"
                speed={3000}
                modules={[Autoplay]}
                className="mySwiper certificate-slider"
                slidesPerView={'auto'}
                breakpoints={{
                  30: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                }}
              >
                {certificationsData.images.map((data) => {
                  return (
                    <SwiperSlide>
                      <img src={data.image} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </section>

          <section
            className={`awards-s ${isSafari ? 'ios-safari' : ''}`}
            // data-scroll-section
            style={{
              backgroundImage: `url(${width > 767 ? Award : mobileAwardsBg})`,
              overflow: "hidden",
            }}
          >
            <div className="awards_sec_main">
              <div className="row align-items-center">
                <div
                  className="col-lg-5 col-md-5 col-sm-12 col-12"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="2.5"
                >
                  <motion.div
                    className="awards-s_heading"
                    variants={textVariants("down", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h1>{awardsData.title}</h1>
                  </motion.div>
                  <motion.div
                    className="awards_content"
                    variants={textVariants("down", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="awards_s_content_ph">{awardsData.content}</p>
                  </motion.div>
                  <div className="hero-btn-d">
                    <Link to="/awards-&-recognition">
                      <motion.div
                        class="cta"
                        variants={textVariants("down", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Button className="pl-0">
                          View more
                          <CTASvg />
                        </Button>
                      </motion.div>
                    </Link>
                  </div>
                </div>
                <div
                  className="col-lg-7 col-md-7 col-sm-12 col-12"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="0.5"
                >
                  <Swiper
                    // pagination={pagination}
                    // modules={[Pagination]}
                    loop={true}
                    freeMode={true}
                    autoplay={{
                      delay: 0.5,
                    }}
                    noSwiping={true}
                    noSwipingClass="swiper-slide"
                    speed={3000}
                    modules={[Autoplay]}
                    className="mySwiper awards-slider"
                    slidesPerView={3}
                    spaceBetween={60}
                    breakpoints={{
                      30: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                      },
                      768: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                      },
                    }}
                  >
                    {awardsData.images.map((data) => {
                      return (
                        <SwiperSlide>
                          <img src={data.image} width={200} height={200} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>

                  <div className="hero-btn-d mobile_">
                    <Link to="/awards-&-recognition">
                      <motion.div
                        class="cta"
                        variants={textVariants("down", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Button className="pl-0">
                          View more
                          <CTASvg />
                        </Button>
                      </motion.div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Home;
