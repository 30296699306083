import "./App.css";
import "./locomotive-scroll.css";
import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Gallery from "./pages/Gallery";
import Career from "./pages/Career";
import AnimatedCursor from "react-animated-cursor";
import Privacy from "./pages/Privacy";
import "bootstrap/dist/css/bootstrap.min.css";
import Financial from "./pages/Financial/Financial";
import ProductListing from "./pages/ProductListing/ProductListing";
import ProductInner from "./pages/ProductInner/ProductInner";
import Packaging from "./pages/Packaging&Dispatch/Packaging&Dispatch";
import Testing from "./pages/Testinfacilites/Testinfacilites";
import Overview from "./pages/QualityOverview/QualityOverview";
import Certification from "./pages/Quality/Certifications";
import { CustomerApproval } from "./pages/Quality/CustomerApproval";
import Indigenious from "./pages/Clients/Indigenious";
import Applications from "./pages/Applications/Applications";
import Awards from "./pages/Awards&Recognition/Awards&Recognition";
import OverseasPresence from "./pages/Clients/OverseasPresence";
import Maunfacutring from "./pages/Manufacturingprocess/Manufacturingprocess";
import Acrspinner from "./pages/ManufacturingprocessInner/Acrspinner";
import Annealingfurnace from "./pages/ManufacturingprocessInner/Annealingfurnace";
import Furnace from "./pages/ManufacturingprocessInner/Furnance";
import Billetheater from "./pages/ManufacturingprocessInner/Billetheater";
import Extrusion from "./pages/ManufacturingprocessInner/Extrusion";
import Pilger from "./pages/ManufacturingprocessInner/Pilger";
import Billetsaw from "./pages/ManufacturingprocessInner/Billetsaw";
import Drawbench from "./pages/ManufacturingprocessInner/Drawbench";
import Straightener from "./pages/ManufacturingprocessInner/Straightener";
import Contactus from "./pages/Contactus/Contactus";
import NotFound from "./pages/NotFound";
import HeaderNew from "./Header/Header";
import Msg from "./assets/mail.png";
import Call from "./assets/Call.svg";
import ThankYou from "./pages/ThankYou/ThankYou";

function App() {
  return (
    <>
      <>
        <BrowserRouter>
          <HeaderNew />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/career" element={<Career />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/financial" element={<Financial />} />
            <Route
              exact
              path="/products/:categorySlug"
              element={<ProductListing />}
            />
            <Route
              exact
              path="/products/:categorySlug/:productSlug"
              element={<ProductInner />}
            />
            <Route exact path="/packaging-&-dispatch" element={<Packaging />} />
            <Route exact path="/testing-facilities" element={<Testing />} />
            <Route
              exact
              path="/manufacturing-process"
              element={<Maunfacutring />}
            />
            <Route exact path="/quality-overview" element={<Overview />} />
            <Route exact path="/awards-&-recognition" element={<Awards />} />
            <Route exact path="/certifications" element={<Certification />} />
            <Route exact path="/acr-spinner" element={<Acrspinner />} />
            <Route
              exact
              path="/annealing-furnace"
              element={<Annealingfurnace />}
            />
            <Route exact path="/furnance" element={<Furnace />} />
            <Route
              exact
              path="/electric-induction-billet-heater"
              element={<Billetheater />}
            />
            <Route exact path="/extrusion" element={<Extrusion />} />
            <Route exact path="/pilger" element={<Pilger />} />
            <Route exact path="/billet-saw" element={<Billetsaw />} />
            <Route exact path="/draw-bench" element={<Drawbench />} />
            <Route exact path="/straightener" element={<Straightener />} />
            <Route exact path="/contact-us" element={<Contactus />} />
            <Route
              exact
              path="/vendor-approval"
              element={<CustomerApproval />}
            />
            <Route exact path="/indigenous" element={<Indigenious />} />
            <Route
              exact
              path="/overseas-presence"
              element={<OverseasPresence />}
            />
            <Route exact path="/applications" element={<Applications />} />
            <Route exact path="/thank-you" element={<ThankYou />} />
            <Route exact path="/404" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </BrowserRouter>
      </>
      <div>
        <a href="mailto:info@multimetals.in">
          <button
            style={{
              borderRadius: "5.745744096650192vw",
              transition: "auto",
            }}
            className="scrollTop show_btn QUICK_QUOTE mail-btn"
          >
            <img src={Msg} />
          </button>
        </a>

        {/* <a href="contact-us">
          <button
            style={{
              borderRadius: "5.745744096650192vw",
              transition: "auto",
            }}
            className="scrollTop show_btn QUICK_QUOTE "
          >
            <img src={Call} />
          </button>
        </a> */}
      </div>

      <AnimatedCursor
        innerSize={8}
        outerSize={32}
        innerScale={1}
        outerScale={1}
        outerAlpha={0}
        trailingSpeed={1}
        // showSystemCursor={true}
        outerStyle={{
          border: "4px solid #FFE4CD",
        }}
        innerStyle={{
          backgroundColor: "#FFE4CD",
        }}
        clickables={[
          "a",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
        ]}
      />
    </>
  );
}

export default App;
