import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import SubNavItem from "./SubNavItem";
import { useWindowSize } from "react-use";
import { TfiAngleDown } from "react-icons/tfi";
import Whitelogo from "../assets/WhiteLogo.png";
import Download from "../assets/image/img3.png";
import { images, downArrow } from "../assets";
import leftArrow from "../assets/left_arrow.png";

const HeaderNavItem = ({
  navData,
  navActiveIndex,
  handleNavIndex,
  arrIndex,
}) => {
  const { width } = useWindowSize();
  const [subIndex, setSubIndex] = useState(0);
  const [subWrap, setSubWrap] = useState(false);

  const handleSubIndex = (i) => {
    if (subIndex == i) {
      setSubIndex(null);
    } else {
      setSubIndex(i);
    }
  };

  const handleLevelTwoIndex = (i) => {
    if (subIndex == i) {
      setSubIndex(null);
    } else {
      setSubIndex(i);
    }
  };

  if (navData.dropDownLevel === 2) {
    return (
      <li className="nav_item">
        <div className="nav_link_wrapper">
          <span className="nav_link">{navData.mainTitle}</span>
          {width >= 1024 ? (
            // <i
            //   className="icon_arrows"
            //   style={{ color: "#fff", fontSize: "12px", marginLeft: "5px" }}
            // >
            //   <TfiAngleDown />
            // </i>
            <img className="NavDownArrow" src={downArrow} alt="" />
          ) : (
            <span className="headerdownarrow">
              {navActiveIndex == arrIndex ? "-" : "+"}
            </span>
          )}
          <div
            className="down-arrow-nav"
            onClick={() => {
              handleNavIndex(arrIndex);
              setSubWrap(true);
            }}
          >
            <img className="left_arrow" width={28} src={leftArrow} alt="" />
          </div>
        </div>

        {width > 992 ? (
          <div
            className={`dropdown_box multi-dropdown ${
              navActiveIndex == arrIndex ? "active" : ""
            }`}
          >
            <>
              {navData.dropDownList.map((dropDownData, i) => (
                <SubNavItem
                  dropDownData={dropDownData}
                  key={i}
                  dropDownIndex={i}
                  handleSubIndex={handleSubIndex}
                  subIndex={subIndex}
                />
              ))}
            </>
          </div>
        ) : (
          <div className={`inside_wrap ${subWrap ? "active" : ""}`}>
            <div className="logo_wrapper_mobile">
              <img
                src={Whitelogo}
                alt="Multi Metal logo"
                className="multimetal_logo"
              />
            </div>
            <div className="back_btn" onClick={() => setSubWrap(false)}>
              <img
                src={images.leftarrow.image}
                alt={images.leftarrow.alt}
                className="left_arrow"
              />
              Back
            </div>
            {navData.dropDownList.map((item, i) => (
              <li className="nav_item product_nav">
                <div className="nav_link_wrapper product_nav_item">
                  {item.dropDowntitle ? (
                    <Link
                      to={item.dropDownLink}
                      className={`nav_link ${
                        subIndex == item.activeId ? "active" : ""
                      }`}
                    >
                      {item.dropDowntitle}
                    </Link>
                  ) : (
                    <span
                      className={`nav_link ${
                        subIndex == item.activeId ? "active" : ""
                      }`}
                    >
                      {item.dropDowntitle}
                    </span>
                  )}
                  <div
                  className="down-arrow-nav"
                    onClick={() => {
                      handleLevelTwoIndex(item.activeId);
                    }}
                  >
                    <img src={downArrow} width={28} alt="" />
                  </div>
                </div>

                <div
                  className={`dropdown_box ${subIndex == i ? "active" : ""}`}
                >
                  {item.linkArr.map((data, i) => (
                    <div className="dropdown_list_item" key={i}>
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "dropdown_list_item_link active"
                            : "dropdown_list_item_link"
                        }
                        to={data.navLink}
                      >
                        {data.navTitle}
                      </NavLink>
                    </div>
                  ))}
                </div>
              </li>
            ))}
            <li
              className="download-mobile-btn"
              style={{
                position: "absolute",
                bottom: "5%",
                left: "15px",
                listStyle: "none",
              }}
            >
              <div className="down_brohcer">
                <div className="download_icon">
                  <button>
                    <img src={Download} />
                  </button>
                </div>
                <p style={{ marginLeft: "5px" }}>Download Brochure</p>
              </div>
            </li>
          </div>
        )}
      </li>
    );
  }

  if (navData.dropDownLevel === 1) {
    return (
      <li className="nav_item">
        <div className="nav_link_wrapper">
          {navData.navLink ? (
            <Link to={navData.navLink} className="nav_link">
              {navData.mainTitle}
            </Link>
          ) : (
            <span className="nav_link">{navData.mainTitle}</span>
          )}

          {width >= 1024 ? (
            // <i style={{ color: "#fff", fontSize: "12px", marginLeft: "5px" }}>
            //   <TfiAngleDown />
            // </i>
            <img className="NavDownArrow" width={28} src={downArrow} alt="" />
          ) : (
            // <img
            //   src={headerdownarrow}
            //   alt="Down arrow"
            //   className="headerdownarrow"
            // />
            <span className="headerdownarrow">
              {navActiveIndex == arrIndex ? "-" : "+"}
            </span>
          )}
          <div
            className="down-arrow-nav"
            onClick={() => handleNavIndex(arrIndex)}
          >
            <img src={downArrow} alt="" width={28} />
          </div>
        </div>

        <div
          className={`dropdown_box ${
            navActiveIndex == arrIndex ? "active" : ""
          }`}
        >
          {/* {width >= 1024 && (
            <img
              src={dropdownboxuparrow}
              alt="dropdown box up arrow"
              className="dropdownboxuparrow"
            />
          )} */}
          {navData.dropDownList.map((data, i) => (
            <div className="dropdown_list_item" key={i}>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? "dropdown_list_item_link active"
                    : "dropdown_list_item_link"
                }
                to={data.navLink}
              >
                {data.navTitle}
              </NavLink>
            </div>
          ))}
        </div>
      </li>
    );
  }

  return (
    <li className="nav_item">
      <NavLink
        className={({ isActive }) =>
          isActive ? "nav_link active" : "nav_link"
        }
        to={navData.mainLink}
      >
        {navData.mainTitle}
      </NavLink>
    </li>
  );
};

export default HeaderNavItem;
