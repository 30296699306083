import React, { useEffect, useRef, useState } from "react";
import FsLightbox from "fslightbox-react";
import "../Quality/Certifications.scss";
import { images } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Banner from "../../assets/Quality/certifications.jpg";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import { useWindowSize } from "react-use";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

// const certificationArr = [
//   {
//     id: 1,
//     img: images.certificate1.image,
//   },
//   {
//     id: 2,
//     img: images.certificate2.image,
//   },
//   {
//     id: 3,
//     img: images.certificate3.image,
//   },
//   {
//     id: 4,
//     img: images.certificate4.image,
//   },
//   {
//     id: 5,
//     img: images.certificate5.image,
//   },
//   {
//     id: 6,
//     img: images.certificate6.image,
//   },
//   {
//     id: 7,
//     img: images.certificate7.image,
//   },
//   {
//     id: 8,
//     img: images.certificate8.image,
//   },
//   {
//     id: 9,
//     img: images.certificate9.image,
//   },
//   {
//     id: 10,
//     img: images.certificate10.image,
//   },
//   {
//     id: 11,
//     img: images.certificate11.image,
//   },
//   {
//     id: 12,
//     img: images.certificate12.image,
//   },
//   {
//     id: 13,
//     img: images.certificate13.image,
//   },
//   {
//     id: 14,
//     img: images.certificate14.image,
//   },
//   {
//     id: 15,
//     img: images.certificate15.image,
//   },
//   {
//     id: 16,
//     img: images.certificate16.image,
//   },
// ];

const Certification = () => {
  const scrollRef = useRef(null);
  const { width } = useWindowSize();
  const footerRef = useRef(null);

  const [toggler1, setToggler1] = useState(false);
  const [toggler2, setToggler2] = useState(false);
  const [toggler3, setToggler3] = useState(false);
  const [toggler4, setToggler4] = useState(false);
  const [toggler5, setToggler5] = useState(false);
  const [toggler6, setToggler6] = useState(false);

  // const [lightboxController, setLightboxController] = useState({
  //   toggler: false,
  //   slide: certificationArr.id,
  // });

  // function openLightboxOnSlide(number) {
  //   setLightboxController({
  //     toggler: !lightboxController.toggler,
  //     slide: number,
  //   });
  // }

  // const QualityAssurance = certificationArr.map((item, i) => (
  //   <div className="col-6 col-sm-6 col-md-4 p-0">
  //     <motion.div
  //       variants={textVariants("up", 0.2)}
  //       initial="hidden"
  //       whileInView="show"
  //       viewport={{ once: true, amount: 0.1 }}
  //       transition={{ duration: 0.5 }}
  //     >
  //       <div className="application_box">
  //         <img
  //           src={item.img}
  //           alt=""
  //           style={{
  //             width: "100%",
  //             maxWidth: "",
  //             height: "100%",
  //             maxHeight: "375px",
  //           }}
  //           onClick={() => openLightboxOnSlide(item.id)}
  //         />
  //       </div>
  //     </motion.div>
  //   </div>
  // ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    // const accordionElement = footerRef.current;
    // if (accordionElement) {
    //   accordionElement.addEventListener("click", () => {
    //     scroll.update(); // Update scroll calculations
    //   });
    //   accordionElement.addEventListener("accordion.close", () => {
    //     scroll.update(); // Update scroll calculations
    //   });
    // }

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        className="certification_wrap_locomotive"
        ref={scrollRef}
        data-scroll-container
        style={{
          background: "#141414",
          backgroundImage: `url(${Banner})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="certificate_sec1"
          style={{
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div
            className="certification_bg"
            // style={{ backgroundImage: `url(${Banner})` }}
            style={{ background: "transparent" }}
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-class="appear"
            data-scroll-repeat="true"
          >
            <div
              className="certification_breadcrumbs"
              data-scroll
              data-scroll-direction="vertical"
              data-scroll-class="appear"
              data-scroll-repeat="true"
            >
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/">QUALITY</Link>
                <Link to="/certifications">CERTIFICATIONS</Link>
              </Breadcrumbs>
            </div>
            <div
              className="certificate_heading text-center"
              data-scroll
              data-scroll-direction="vertical"
              data-scroll-class="appear"
              data-scroll-repeat="true"
            >
              <h2>CERTIFICATIONS</h2>
            </div>
            <div
              className="scroll-down-d"
              data-scroll
              data-scroll-direction="vertical"
              data-scroll-class="appear"
              data-scroll-repeat="true"
            >
              <div className="spinner scroll-down" style={{ zIndex: "5" }}>
                <a
                  style={{ cursor: "pointer" }}
                  href="#certifications"
                  data-scroll-to
                  className="animate"
                ></a>{" "}
              </div>
            </div>
          </div>
        </section>

        <section
          id="certifications"
          className="certificate_sec2"
          data-scroll-section
          style={{
            background: "#141414",
            height: "fit-content",
            overflow: "hidden",
          }}
        >
          <div
            className="quality-assurance-section"
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-class="appear"
            data-scroll-repeat="true"
          >
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2>QUALITY ASSURANCE</h2>
            </motion.div>
            <div>
              <div>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  At Multimetals, we take immense pride in our renowned and rare
                  certifications, which bestow upon us the authority and
                  compliance to undertake a wide range of projects within the
                  industry. With our unwavering commitment to quality and
                  excellence, we have achieved the prestigious AS9100D
                  certificate, qualifying us to supply top-notch products for
                  the Aerospace and Defence sectors. Our ISO 9001:2015
                  certification, coupled with the esteemed approval of Lloyds
                  Register for our works, further demonstrates our dedication to
                  upholding the highest standards.
                  <br />
                </motion.p>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  We go above and beyond to meet our customers' specific needs
                  and requirements. Our commitment to precision and quality is
                  reflected in the coveted PED Certification and
                  self-certification AD 2000 Merkblatt W6/2 from TUV Nord,
                  serving as a testament to our unwavering pursuit of eminence.
                  <br />
                </motion.p>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  Furthermore, we have been granted the NABL certification as
                  per ISO/IEC 17025:2017, further solidifying our position as a
                  trusted provider of exceptional quality products. <br />
                </motion.p>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  Our exceptional compliance standards and prestigious
                  certifications have positioned us as preferred vendors across
                  various industries globally, spanning five continents. Our
                  esteemed clientele includes industry leaders such as Saudi
                  Aramco, Shell, ADMA, Thyssenkrupp, Kelvion Group, and many
                  more, reaffirming our reputation for supreme quality. <br />
                </motion.p>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  We continuously strive to exceed industry standards, offering
                  impeccable products and custom-made solutions that meet the
                  unique requirements of our valued customers. With our
                  exceptional certifications and unrivaled expertise, we are
                  your trusted partner for excellence and compliance in the
                  industry. <br />
                </motion.p>
                <motion.p
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.75 }}
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                >
                  Our commitment to excellence extends to our in-house R&D
                  facility, which has received official approval from the
                  Government of India. In addition, we proudly hold
                  registrations with esteemed organizations like Engineers India
                  Limited and Indian Defense. These accolades highlight our
                  dedication to driving innovation and maintaining the highest
                  quality standards at all times.
                </motion.p>
              </div>
            </div>
            <div className="row certificate_row">
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate1.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler1(!toggler1)}
                    />
                  </div>

                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate2.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler2(!toggler2)}
                    />
                  </div>

                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate3.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler3(!toggler3)}
                    />
                  </div>

                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate5.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler4(!toggler4)}
                    />
                  </div>

                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate10.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler5(!toggler5)}
                    />
                  </div>

                </motion.div>
              </div>
              <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate15.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler6(!toggler6)}
                    />
                  </div>

                </motion.div>
              </div>
              {/* <div className="col-6 col-sm-6 col-md-4 p-0">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="application_box">
                    <img
                      src={images.certificate12.image}
                      alt=""
                      style={{
                        width: "100%",
                        maxWidth: "",
                        height: "100%",
                        maxHeight: "375px",
                      }}
                      onClick={() => setToggler6(!toggler6)}
                    />
                  </div>

                </motion.div>
              </div> */}

            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
      <FsLightbox
        toggler={toggler1}
        sources={[
          images.certificate1.image,
        ]}
      />
      <FsLightbox
        toggler={toggler2}
        sources={[
          images.certificate2.image,
        ]}
      />
      <FsLightbox
        toggler={toggler3}
        sources={[
          images.certificate3.image,
          images.certificate4.image,
        ]}
      />
      <FsLightbox
        toggler={toggler4}
        sources={[
          images.certificate5.image,
          images.certificate6.image,
          images.certificate7.image,
          images.certificate8.image,
          images.certificate9.image,
        ]}
      />
      <FsLightbox
        toggler={toggler5}
        sources={[
          images.certificate10.image,
          images.certificate11.image,
          images.certificate12.image,
          images.certificate13.image,
        ]}
      />
      <FsLightbox
        toggler={toggler6}
        sources={[
          images.certificate15.image,
          images.certificate16.image,
        ]}
      />
      {/* <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={[
          images.certificate1.image,
          images.certificate2.image,
          images.certificate3.image,
          images.certificate4.image,
          images.certificate5.image,
          images.certificate6.image,
          images.certificate7.image,
          images.certificate8.image,
          images.certificate9.image,
          images.certificate10.image,
          images.certificate11.image,
          images.certificate12.image,
          images.certificate13.image,
          images.certificate14.image,
          images.certificate15.image,
          images.certificate16.image,
        ]}
      /> */}
    </>
  );
};

export default Certification;
