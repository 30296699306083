import React, { useEffect, useRef } from "react";
import "../Applications/Applications.scss";
import { images } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { useWindowSize } from "react-use";
import Footer from "../../Footer/Footer";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const applicationArr = [
  {
    id: 1,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> </ul>",
    title: "AUTOMOBILE INDUSTRY",
    image: images.automobileIndustry.image,
  },
  {
    id: 2,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper </a> </li> <li> <a href='/products/tube-and-pipes/copper'>DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> CuNifer </a> </li> <li> <a href='/products/tube-and-pipes/admiralty-brass'> Ad Brass </a> </li> <li><a href='/products/tube-and-pipes/aluminium-brass'> Al Brass </a>  </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> </ul>",
    title: "POWER PLANTS",
    image: images.powerPlant.image,
  },
  {
    id: 3,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> </ul>",
    title: "AEROSPACE INDUSTRY",
    image: images.aerospace.image,
  },
  {
    id: 4,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> FRTP Copper</a>  </li> <li> <a href='/products/tube-and-pipes/copper'>DHP Copper</a>  </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a>  </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 70/30 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Naval Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 90/10 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 60/40 Brass </a> </li> </ul>",
    title: "DEFENSE APPLICATIONS",
    image: images.defense.image,
  },
  {
    id: 5,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/aluminium-brass'> Al Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 65/35 Brass </a> </li> </ul>",
    title: "DESALINATION PLANTS",
    image: images.desalination.image,
  },
  {
    id: 6,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li><a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> </ul>",
    title: "SUGAR & CHEMICAL INDUSTRY",
    image: images.sugar.image,
  },
  {
    id: 7,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 70/30 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> CuNifer </a> </li> <li> <a href='/products/tube-and-pipes/admiralty-brass'> Ad Brass </a> </li> <li> <a href='/products/tube-and-pipes/aluminium-brass'> Al Brass </a> </li> </ul>",
    title: "OIL & GAS INDUSTRY",
    image: images.oil.image,
  },
  {
    id: 8,
    content1:
      "<ul> <li>  <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> FRTP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Naval Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 60/40 Brass </a> </li> </ul>",
    title: "SHIP BUILDING & REPAIRS",
    image: images.ship.image,
  },
  {
    id: 9,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 70/30 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> CuNifer </a> </li> <li> <a href='/products/tube-and-pipes/admiralty-brass'> Ad Brass </a> </li> <li> <a href='/products/tube-and-pipes/aluminium-brass'> Al Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Red Brass 85/15 </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/copper-aresenic-aluminium-bronze'> Cu-As-Al Bronze </a>  </li> </ul>",
    title: "HEAT EXCHANGERS, CONDENSERS",
    image: images.heat.image,
  },
  {
    id: 10,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> </ul>",
    title: "EVAPOURATOR",
    image: images.evaporator.image,
  },
  {
    id: 11,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> </ul>",
    title: "CHILLER",
    image: images.chiller.image,
  },
  {
    id: 12,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> </ul>",
    title: "BEARING & BUSHING",
    image: images.bearing.image,
  },
  {
    id: 13,
    content1:
      "<ul> <li>  <a href='/products/tube-and-pipes/copper'>ETP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Red Brass 85/15 </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> </ul>",
    title: "SANITRY & PLUMBERING",
    image: images.sanitry.image,
  },
  {
    id: 14,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper-aresenic-aluminium-bronze'> Cu-As-Al Bronze </a> </li> </ul>",
    title: "FERTILIZERS & POTASH INDUSTRIES",
    image: images.fertilizer.image,
  },
  {
    id: 15,
    content1:
      "<ul> <li> <a href='/products/tube-and-pipes/copper'> DHP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper'> DLP Copper</a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 95/5 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 90/10 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> 70/30 CuNi </a> </li> <li> <a href='/products/tube-and-pipes/copper-nickel'> CuNifer </a> </li> <li> <a href='/products/tube-and-pipes/admiralty-brass'> Ad Brass </a> </li> <li> <a href='/products/tube-and-pipes/aluminium-brass'> Al Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Naval Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> Red Brass 85/15 </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 70/30 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 63/37 Brass </a> </li> <li> <a href='/products/tube-and-pipes/plain-brasses'> 60/40 Brass </a> </li> <li> <a href='/products/tube-and-pipes/copper-aresenic-aluminium-bronze'> Cu-As-Al Bronze </a> </li> </ul>",
    title: "FITTINGS",
    image: images.fitting.image,
  },
];

const Applications = () => {
  const scrollRef = useRef(null);
  const { width } = useWindowSize();
  const app = applicationArr.map((item) => (
    <motion.div
      variants={textVariants("up", 0.2)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true, amount: 0.1 }}
      transition={{ duration: 0.5 }}
      className="col-md-6 col-lg-4 parent"
    >
      <div className="appp">
        <div className="Application_card_external_container">
          <div
            className="image_holder"
            style={{
              backgroundImage: `url(${item.image})`,
              // backgroundSize: "cover",
            }}
          >
            <div className="Application-card">
              <div
                className="Application-card-content"
                dangerouslySetInnerHTML={{ __html: item.content1 }}
              ></div>
            </div>
          </div>
        </div>
        <div className="Application-card-heading">
          <h2>{item.title}</h2>
        </div>
      </div>
    </motion.div>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.application.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="application_sec1"
          style={{
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div
            className="gallery_bg application_bg"
            // style={{ backgroundImage: `url(${images.application.image})` }}
          >
            <div className="gallery_breadcrumbs app_breadcrumbs">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/applications">APPLICATIONS</Link>
              </Breadcrumbs>
            </div>
            <div className="applications_heading">
              <motion.h1
                variants={textVariants("left", 0.2, 2.5)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                APPLICATIONS
              </motion.h1>
            </div>
            <div className="scroll-down-d" style={{ zIndex: "5" }}>
              <div className="spinner scroll-down">
                {" "}
                <a
                  style={{ cursor: "pointer" }}
                  href="#application"
                  data-scroll-to
                  className="animate"
                ></a>{" "}
              </div>
            </div>
            <div className="application_img_wrapper">
              <motion.img
                variants={textVariants("right", 0.2, 2.5)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
                src={images.copperpipe.image}
                alt={images.copperpipe.alt}
                className="application_banner_copper_img"
              />
            </div>
          </div>
        </section>
        <section
          id="application"
          className="application_sec2"
          style={{ height: "100%", overflow: "hidden" }}
          data-scroll-section
        >
          <div className="application-content-section">
            <motion.h3
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              Metals that Shape Industries: Harnessing the Versatility and
              Strength of Metal
            </motion.h3>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              Our materials are utilized in the automotive sector, shipbuilding
              and repairs, power plants, defense applications, aerospace
              industry, desalination plants, sugar and chemical industry, oil
              and gas industry, and fittings.
            </motion.p>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              In the automotive industry, we offer reliable copper and copper
              alloy components for radiators, heat exchangers, and electrical
              parts. For shipbuilding and repair, our marine-grade pipes,
              fittings, and valves are trusted for their durability. Power
              plants benefit from our efficient copper conductors and heat
              exchanger tubes.
            </motion.p>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              Multimetals play a vital role in defense applications, providing
              high-quality copper and copper alloy solutions for military
              vehicles, communication systems, weaponry, and armor. Desalination
              plants, sugar mills, and chemical plants rely on our
              corrosion-resistant materials for heat exchangers and piping
              systems.
            </motion.p>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              In the oil and gas industry, our copper and copper alloy products
              are used in offshore platforms, refineries, and pipelines due to
              their exceptional thermal conductivity and corrosion resistance .
              We also cater to the fittings offering a comprehensive range of
              reliable materials for plumbing and HVAC systems.
            </motion.p>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              We are committed to meeting the requirements of our clients by
              delivering outstanding products. Explore our offerings and
              discover how our expertise and dedication to quality can fulfill
              your needs.
            </motion.p>

            <div
              className="row application_row"
              style={{ rowGap: "3rem", marginTop: "80px" }}
            >
              {app}
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Applications;
