import { React, useEffect, useState } from "react";
import "./testinfacilites.scss";
import Commonbanner from "../../Common Banner/CommonBanner";
import {
  icon1,
  icon2,
  icon3,
  images,
  leftArrow,
  rightArrow,
  testingBanner,
} from "../../assets";
import { useWindowSize } from "react-use";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import { useRef } from "react";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Testing = () => {
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tableData = [
    {
      id: 1,
      make: `<ul>
        <li>Bruker Germany</li>
        <li>Worldwide Analytical Systems Germany</li>
        <li>Jarrel Ash USA</li>
        <li>Varian BV Netherlandsy</li>
        <li>Werkstoffprofmachinen Germany</li>
        <li>Herckert Germany</li>
        <li>Baird & Tatock UK</li>
        <li>Foerster Germany</li>
        <li>GE Kraut Kramer Germany</li>
        <li>Mitutuyo Japan</li>
        <li>Olympus Japan</li>
        <li>Carl Zeiss USA</li>
        <li>Dewinter Optical Inc. Italy</li>
        </ul>`,
      machinery: `<ul>
       <li>CPM Based Optical Emission Spectrometer</li>
       <li>Optical Emission Spectrometer – 2 Nos.</li>
       <li>Direct Reading Emission Spectrometer</li>
       <li>Atomic Absorption Spectrometer</li>
       <li>Universal Testing Machine Capacity – 20 Tonnes</li>
       <li>Vickers Brinell Hardness Tester</li>
       <li>Electrolytic Analyzer</li>
       <li>Eddy Current Testing Machine - 2 Nos.</li>
       <li>Ultrasonic Testing Machine</li>
       <li>Profile Projector</li>
       <li>Inverted Metallurgical Microscope 1000 X</li>
       <li>Metallurgical Microscope</li>
       <li>Image Analyzing Software</li>
       </ul>`,
    },
  ];

  const tableList = tableData.map((item, i) => (
    <tr className="content" key={i}>
      <td
        className="table_desc"
        dangerouslySetInnerHTML={{ __html: item.make }}
      ></td>
      <td
        className="table_desc"
        dangerouslySetInnerHTML={{ __html: item.machinery }}
      ></td>
    </tr>
  ));

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      let pageNumber = index + 1;
      if (pageNumber < 10) {
        pageNumber = "0" + pageNumber;
      }
      return '<span class="' + className + '">' + pageNumber + "</span>";
      // return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const testingData = [
    {
      id: 1,
      img: images.testing1.image,
      alt: images.testing1.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting
      // industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
      // is simply dummy text of the printing and typesetting industry. Lorem
      // Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy
      // text of the printing and typesetting industry. Lorem Ipsum `,
    },
    {
      id: 2,
      img: images.testing2.image,
      alt: images.testing2.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting
      // industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
      // is simply dummy text of the printing and typesetting industry. `,
    },
    {
      id: 3,
      img: images.testing3.image,
      alt: images.testing3.alt,
      // details: `Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
      // is simply dummy text of the printing and typesetting industry. Lorem
      // Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy
      // text of the printing and typesetting industry. Lorem Ipsum `,
    },
    {
      id: 4,
      img: images.testing4.image,
      alt: images.testing4.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting
      // industry. Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
      // is simply dummy text of the printing and typesetting industry. Lorem
      // Ipsum has been the industry's standard dummy Lorem Ipsum is simply dummy
      // text of the printing and typesetting industry. Lorem Ipsum `,
    },
    {
      id: 5,
      img: images.testing5.image,
      alt: images.testing5.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting
      // industry.`,
    },
  ];

  const testingList = testingData.map((item, i) => (
    <SwiperSlide key={testingData[i].id}>
      <div className="testing_content">
        <img
          // height={780}
          className="testing_img"
          src={item.img}
          alt={item.alt}
        />
        <p className="testing_details">{item.details}</p>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${testingBanner})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="testing_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            // image={testingBanner}
            linkText="QUALITY"
            link="/quality"
            linkText1="TESTING FACILITIES"
            link1="/testing-facilities"
            headingtext="TESTING FACILITIES"
            scrollId="#testing"
          />
          <div className="icon_container">
            <motion.div
              variants={textVariants("up", 0.2, 2.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={icon1} alt="icon" className="icon_img" />
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2, 2.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={icon2} alt="icon" className="icon_img icon_img1" />
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2, 2.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={icon3} alt="icon" className="icon_img" />
            </motion.div>
          </div>
        </section>

        <section
          id="testing"
          className="testing_sec2"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="content_container">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">TESTING FACILITIES</h2>
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                Multimetals strictly adheres to stringent quality control
                measures throughout our production process. Our commitment to
                delivering the best quality products begins with conducting
                rigorous tests on incoming raw materials, intermediate stages,
                and finished products, all in accordance with our comprehensive
                Quality Assurance Plan. To ensure that our products meet the
                highest standards, we have a dedicated team of trained
                supervisors and quality control inspectors who diligently
                monitor and maintain quality at every stage of production.
                <br /> <br />
                Our primary objective is to provide customers with high-quality
                products that comply with various international standards and
                meet specific customer specifications. To achieve this, we
                meticulously document the entire process, ensuring consistent
                and superior quality across all product lots. At Multimetals, we
                leverage state-of-the-art testing facilities to conduct a wide
                range of tests, including physical, electrical, mechanical, and
                others, to guarantee the exceptional quality of our products.
                <br />
                Our systems have benchmark Non-Destructive Testing equipment,
                such as Hydro static Testing and Eddy Current Testing. We have a
                sophisticated Laboratory which is approved by the Govt. of
                India’s Department of Science and Technology and NABL, which
                makes our organization apt for the future.
                <br /> <br />
                With these advanced tools at our disposal, we leave no stone
                unturned in our pursuit of delivering products that exceed
                expectations and uphold our reputation for supremacy.
              </p>
            </motion.div>
          </div>

          <div className="testing_slider">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <Swiper
                autoHeight={true}
                loop={true}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                spaceBetween={32}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 300,
                  modifier: 1,
                  slideShadows: false,
                }}
                navigation={{
                  prevEl: ".left_arrow",
                  nextEl: ".right_arrow",
                }}
                pagination={pagination}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
                className="mySwiper"
                breakpoints={{
                  0: {
                    spaceBetween: 93,
                    autoHeight: true,
                  },
                  768: {
                    spaceBetween: 230,
                  },
                }}
              >
                {testingList}
              </Swiper>
              <div className="arrow_wrapper">
                <img src={leftArrow} alt="left arrow" className="left_arrow" />
                <img
                  src={rightArrow}
                  alt="right arrow"
                  className="right_arrow"
                />
              </div>
            </motion.div>
          </div>
        </section>

        <section
          className="table_container"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="table_equipment">
              FOLLOWING ARE THE TESTING EQUIPMENTS AT OUR FACILITIES
            </h2>
          </motion.div>
          <motion.div
            variants={textVariants("up", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="testing_table">
              <table className="table_content">
                <tr className="heading">
                  <th className="table_heading">Make</th>
                  <th className="table_heading1">Machinery</th>
                </tr>
                {tableList}
              </table>
            </div>
          </motion.div>
        </section>

        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Testing;
