import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { innerFurnance } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Furnace = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="FURNANCE"
          link2="/furnance"
        /> */}
        <section id="scrolltop" className="furnace_sec1">
          <div className="row furnace_container">
            <div className="col-md-5 furnace_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="furnace_heading">FURNANCE</h2>
                <p className="furnace_details">
                  The first step in the manufacturing process at Multimetals
                  involves the use of a high-temperature furnace. This furnace
                  is specifically designed to withstand extreme heat and is
                  instrumental in the creation of superior-quality alloys.
                  Cathode copper, known for its exceptional purity, is carefully
                  melted in the furnace along with other meticulously selected
                  metals. Through this process, the unique properties of each
                  metal combine to form alloys of exceptional quality. The
                  molten alloy is then poured into precision molding casts,
                  where it undergoes a diligent cooling and solidification
                  process. This ensures that the alloy takes on the desired
                  shape and structure, guaranteeing the formation of a
                  high-quality product.
                </p>
              </motion.div>
            </div>
            <div className="col-md-7 furnace_content">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={innerFurnance}
                  alt="Furnace"
                  className="furnace_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Furnace;
