import React, { useEffect } from "react";
import { images } from "../assets";
import "./notFound.scss"
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { motion } from "framer-motion";
import { textVariants } from "../Header/Motion";
import { submitButton } from "../assets";



const NotFound = () => {
  return (
    <>
      <section className="privacy_section">
        <div
          className="privacy_sec_bg"
          style={{
            height: "100vh",
            backgroundImage: `url(${images.notfound.image})`,
            overflow: "hidden",
            backgroundSize:"cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
          }}
          
        >
          <motion.div
            className="notfound_content"
            variants={textVariants("left", 0.2)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="heading">
              404
            </h1>
            <p>
              PAGE NOT FOUND
            </p>

            <Link to="/">
              <button
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                Back to home <img src={submitButton} alt="backtohome" />
              </button>
            </Link>
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
