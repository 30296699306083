import React, { useEffect } from "react";
import { preLoaderAnim } from "../Animations/animations";
import "./preloader.scss";

const PreLoader = () => {
  useEffect(() => {
    preLoaderAnim();
  }, []);
  return (
    <div className="preloader">
      {/* <div className="texts-container">
        <span>Loading</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div> */}
    </div>
  );
};

export default PreLoader;
