import React, { useState, useEffect, useRef } from "react";
import Banner from "../assets/Careers/career-banner.jpg";
import mobileBanner from "../assets/Careers/career-bg.jpg";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Home from "../assets/image/img31.png";
import Image1 from "../assets/image/img37.png";
import Image2 from "../assets/image/img33.png";
import Image3 from "../assets/image/img21.png";
import Image4 from "../assets/image/img18.png";
import Table from "react-bootstrap/Table";
import { BiMinus } from "react-icons/bi";
import { BsPlus } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { TfiAngleDown } from "react-icons/tfi";
import Captcha from "../assets/image/img13.png";
import BannerTest from "../assets/home/csr-banner.jpg";
import mobileTestBanner from "../assets/Careers/tesit-bg.png";
import Slider from "react-slick";
import arrowNext from "../assets/left.png";
import { useWindowSize } from "react-use";
import arrowPrev from "../assets/right.png";
import {
  DataTable,
  careerBenefits,
  careerHeroData,
  careerApplyForJob,
} from "../data";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import upload from "../assets/upload.png";
import { textVariants } from "../Header/Motion";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../Footer/Footer";
import CTASvg from "../common/CTASvg";
import PreLoader from "../loader/preloader";
import AnimatedText from "../common/AnimatedText";
import { leftArrow, rightArrow } from "../assets";
const imagesLoaded = require("imagesloaded");
import { isTablet, isSafari } from 'react-device-detect';


const Career = () => {
  const { width } = useWindowSize();
  // const [next, setNext] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [handleDrop, setHandleDrop] = useState(false);
  const recaptchaRef = React.useRef();
  const [value, setValue] = useState();
  const [portfolio, setPortfolio] = useState("");
  const [resume, setResume] = useState("");

  const handleClicked = () => {
    setClicked(!clicked);
    setHandleDrop(false);
  };
  const [clicked1, setClicked1] = useState("panel1");
  const [handleDrop1, setHandleDrop1] = useState(false);

  const handleClicked1 = (panel) => {
    setClicked1((prevPanel) => (prevPanel == panel ? "" : panel));
    setHandleDrop1(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLocation = (event) => {
    setLocation(event.target.value);
  };

  const handleDepartment = (event) => {
    setDepartment(event.target.value);
  };

  const [swiper, setSwiper] = useState(null);

  const pagination = {
    clickable: false,
    renderBullet: function (index, className) {
      return `   <span class=${className}>
            <svg width='26' height='26' viewBox='0 0 28 28'></svg>
            <div class="slider_line"></div>
            <span class="slider_indicator_count_">0${index + 1}</span>
          </span>`;
    },
  };

  const itemData = [
    {
      img: Image1,
    },
    {
      img: Image2,
    },
    {
      img: Image3,
    },
    {
      img: Image4,
    },
    // {
    //   img: Image4,

    // },
    // {
    //   img: Image4,

    // },
  ];

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        style={{
          backgroundImage: `url(${arrowNext})`,
          backgroundRepeat: "no-repeat",
        }}
        onClick={onClick}
        className="slick-arrow slick-next"
      >
        Next
      </button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <button
        style={{
          backgroundImage: `url(${arrowPrev})`,
          backgroundRepeat: "no-repeat",
        }}
        onClick={onClick}
        className="slick-arrow slick-prev"
      >
        Prev
      </button>
    );
  }
  var settings = {
    dots: false,
    speed: 300,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 5470,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 63,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [data, setData] = useState(DataTable);

  // smooth scroll
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver(() => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${width > 767 ? Banner : mobileBanner})`,
          backgroundSize: (width < 1024 || isTablet) ? "100%" : "cover",
          overflow: "hidden",
          backgroundPosition: (width < 1024 || isTablet) ? "top" : "center",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat"
        }}
      >

        <div data-scroll-section >
          <section
            id="scrolltop"
            className="career_section"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div
              className="career_bg"
            // style={{ backgroundImage: `url(${Banner})` }}
            >
              <div className="career_breadcrumbs">
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link to="/">
                    <img src={Home} width={20} />{" "}
                  </Link>

                  <Link to="/career">Career</Link>
                </Breadcrumbs>
              </div>
              <div className="career_heading text-center ">
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                  className="d-flex justify-content-center"
                >
                  <h1>Career</h1>
                  <div className="heading-d"></div>
                </motion.div>
              </div>
              <div className="scroll-down-d" style={{ zIndex: "5" }}>
                <div class="spinner scroll-down">
                  <a
                    style={{ cursor: "pointer" }}
                    href="#career_section"
                    data-scroll-to
                    class="animate"
                  ></a>
                </div>
              </div>
            </div>
          </section>

          <section
            className="multimetal_career_sec"
            id="career_section"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className="multimetal_career_sec_bg">
              <div className="">
                <div className="multimetal_career_content">
                  <div className="d-flex career_d"
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.25"
                  >
                    <div className="multimetal_left_section">
                      <div className="multimetal_left_heading">
                        <motion.div
                          variants={textVariants("left", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <h1>{careerHeroData.title}</h1>
                        </motion.div>
                      </div>
                      <div className="multimetal_left_content">
                        <motion.div
                          variants={textVariants("left", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <p>{careerHeroData.content}</p>
                        </motion.div>
                      </div>
                    </div>
                    <div className="multimetal_right_section">
                      <div className="multimetal_right_heading"

                      >
                        <motion.div
                          variants={textVariants("right", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <AnimatedText text={"CAREERS"} />
                          {/* <h1 className="text-outline" data-text="CAREERS">CAREERS</h1> */}
                        </motion.div>
                      </div>
                      <div className="multimetal_right_content"

                      >
                        <motion.div
                          variants={textVariants("right", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <ImageList
                            variant="woven"
                            cols={2}
                            gap={"1.4583333333333333vw"}
                            style={{ overflowY: "inherit" }}
                          >
                            <ImageListItem className="woven_image_1" style={{}}>
                              <img
                                src={careerHeroData.image1}
                                style={{ width: "400" }}
                                loading="lazy"
                              />
                            </ImageListItem>
                            <ImageListItem
                              className="woven_image_2"
                              style={{ gridRowEnd: "span 3" }}
                            >
                              <img
                                src={careerHeroData.image2}
                                style={{ width: "400" }}
                                loading="lazy"
                              />
                            </ImageListItem>

                            <ImageListItem
                              className="woven_image_3"
                              style={{
                                gridRowEnd: "span 3",
                                gridColumnStart: "span 1",
                              }}
                            >
                              <img
                                src={careerHeroData.image3}
                                style={{ width: "200" }}
                                loading="lazy"
                              />
                            </ImageListItem>
                            <ImageListItem
                              className="woven_image_4"
                              style={{ gridRowEnd: "span 3", alignSelf: "start" }}
                            >
                              <img
                                src={careerHeroData.image4}
                                style={{ width: "400" }}
                                loading="lazy"
                              />
                            </ImageListItem>
                          </ImageList>
                        </motion.div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="benefits_section">
              <div className="benefits_sec_bg">
                <div className="">
                  <div className="benefits_sec_heading"
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.25"
                  >
                    <motion.div
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <h1>BENEFITS</h1>
                    </motion.div>
                  </div>
                  <div className="mobile_slider">
                    <Swiper
                      effect={"coverflow"}
                      grabCursor={true}
                      centeredSlides={true}
                      slidesPerView={1}
                      loop={true}
                      coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 200,
                        modifier: 1,
                        slideShadows: false,
                      }}
                      initialSlide={0}
                      autoplay={false}
                      speed={500}
                      navigation={{
                        nextEl: ".image-swiper-button-next",
                        prevEl: ".image-swiper-button-prev",
                        disabledClass: "swiper-button-disabled",
                      }}
                      spaceBetween={50}
                      pagination={false}
                      modules={[Navigation, Pagination, Autoplay]}
                      className="mySwiper tab_slider"
                      onSwiper={(swiper) => {
                        setSwiper(swiper);
                      }}
                      breakpoints={{
                        30: {
                          slidesPerView: 1,
                        },
                        768: {
                          slidesPerView: 1.5,
                        },
                        1024: {
                          slidesPerView: 1.5,
                        },
                      }}
                    >
                      {careerBenefits?.map((data, index) => {
                        return (
                          <SwiperSlide className="Swiper">
                            <div
                              key={index}
                              className="benefits_section_one d-flex"
                            >
                              <div className="benefits_section_one_heading">
                                <AnimatedText text={data.id} />
                              </div>
                              <div className="benefits_section_one_content">
                                <p>{data.content}</p>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}

                      <div className="custom-swiper-btn-d">
                        <div className="swiper-button image-swiper-button-prev">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                          >
                            <g
                              id="Group_7102"
                              data-name="Group 7102"
                              transform="translate(-0.5 0.063)"
                            >
                              <g
                                id="Ellipse_27"
                                data-name="Ellipse 27"
                                transform="translate(0.5 -0.063)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="0.5"
                              >
                                <circle cx="20" cy="20" r="20" stroke="none" />
                                <circle cx="20" cy="20" r="19.75" fill="none" />
                              </g>
                              <g
                                id="Group_7101"
                                data-name="Group 7101"
                                transform="translate(11.394 24.772) rotate(-90)"
                              >
                                <path
                                  id="Path_922"
                                  data-name="Path 922"
                                  d="M0,5.068S4.491,2.93,4.971,0"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                                <path
                                  id="Path_923"
                                  data-name="Path 923"
                                  d="M5.108,5.049S.02,2.055,0,0"
                                  transform="translate(5.01 0.02)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                                <path
                                  id="Path_924"
                                  data-name="Path 924"
                                  d="M0,17.958V0"
                                  transform="translate(5.011 0.039)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="swiper-button image-swiper-button-next">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                          >
                            <g
                              id="Group_7103"
                              data-name="Group 7103"
                              transform="translate(0.203 0.063)"
                            >
                              <g
                                id="Ellipse_7"
                                data-name="Ellipse 7"
                                transform="translate(-0.203 -0.063)"
                                fill="none"
                                stroke="#fff"
                                stroke-width="0.5"
                              >
                                <circle cx="20" cy="20" r="20" stroke="none" />
                                <circle cx="20" cy="20" r="19.75" fill="none" />
                              </g>
                              <g
                                id="Group_3225"
                                data-name="Group 3225"
                                transform="translate(10.443 24.772) rotate(-90)"
                              >
                                <path
                                  id="Path_922"
                                  data-name="Path 922"
                                  d="M0,0S4.491,2.138,4.971,5.068"
                                  transform="translate(0 12.929)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                                <path
                                  id="Path_923"
                                  data-name="Path 923"
                                  d="M5.108,0S.02,2.994,0,5.049"
                                  transform="translate(5.01 12.929)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                                <path
                                  id="Path_924"
                                  data-name="Path 924"
                                  d="M0,0V17.958"
                                  transform="translate(5.011 0)"
                                  fill="none"
                                  stroke="#fff"
                                  stroke-width="0.5"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Swiper>
                  </div>
                  <div className="hide_mobile"
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.25"
                  >
                    <div className="row ">
                      {careerBenefits?.map((data, index) => {
                        return (
                          <div
                            key={index}
                            className="col-lg-4 col-md-4 col-sm-12 col-12 benefits_section_one"
                          >
                            <motion.div
                              variants={textVariants("up", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <div className="benefits_section_one_heading">
                                <AnimatedText text={data.id} />
                                {/* <h1 className="text-outline" data-text={data.id}>{data.id}</h1> */}
                              </div>
                            </motion.div>{" "}
                            <motion.div
                              variants={textVariants("up", 0.2)}
                              initial="hidden"
                              whileInView="show"
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.5 }}
                            >
                              <div className="benefits_section_one_content">
                                <p>{data.content}</p>
                              </div>
                            </motion.div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>

          <section
            className="location_section"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div className="location_sec_bg">
              <div className=""

              >
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="location_section_heading"
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.25"
                  >
                    <h1>WANT TO BECOME ONE OF US?</h1>
                  </div>
                </motion.div>
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="location_section_content"
                    data-scroll
                    data-scroll-direction="vertical"
                    data-scroll-class="appear"
                    data-scroll-repeat="true"
                    data-scroll-speed="0.25"
                  >
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy Lorem Ipsum is simply dummy text of the
                      printing and typesetting industry.
                    </p>
                  </div>
                </motion.div>

                <div className="location_section_dropdown"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="0.25"
                >
                  <form>
                    <div className="department_">
                      <ul>
                        <motion.div
                          variants={textVariants("up", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <div className="form-control">
                            <label>Department</label>
                            {/* <FormControl sx={{ m: 1, minWidth: 120 }}> */}
                            <Select
                              value={department}
                              onChange={handleDepartment}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              IconComponent={TfiAngleDown}
                            >
                              <MenuItem value="">Human Resources</MenuItem>
                              <MenuItem value={10}>Human Resources</MenuItem>
                              <MenuItem value={20}>Human Resources</MenuItem>
                            </Select>
                            {/* <FormHelperText>Without label</FormHelperText>
                      </FormControl> */}
                            {/* <li class="dropdown">
                   
                    <input type="checkbox" />
                    <a href="#" data-toggle="dropdown">Human Resources</a>
                    <ul class="dropdown-menu">
                      <li><a >Human Resources</a></li>
                      <li><a >Human Resources</a></li>
                      
                    </ul>
                  </li> */}
                          </div>
                        </motion.div>
                      </ul>
                    </div>
                    <div className="location_">
                      <ul>
                        <motion.div
                          variants={textVariants("up", 0.2)}
                          initial="hidden"
                          whileInView="show"
                          viewport={{ once: true, amount: 0.1 }}
                          transition={{ duration: 0.5 }}
                        >
                          <div className="form-control">
                            <label>Location</label>
                            <Select
                              value={location}
                              onChange={handleLocation}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              IconComponent={TfiAngleDown}
                            >
                              <MenuItem value="">Mumbai, India</MenuItem>
                              <MenuItem value={1}>Mumbai, India</MenuItem>
                              <MenuItem value={2}>Delhi, India</MenuItem>
                            </Select>

                            {/* <li class="dropdown">

                        <input type="checkbox" />
                        <a href="#" data-toggle="dropdown">Mumbai, India</a>
                        <ul class="dropdown-menu">
                          <li><a>Mumbai, India</a></li>
                          <li><a >Delhi, India</a></li>

                        </ul>
                      </li> */}
                          </div>
                        </motion.div>
                      </ul>
                    </div>

                    <div className="loacation_dorpdown_btn">
                      <motion.div
                        variants={textVariants("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <button type="submit">SEARCH</button>
                      </motion.div>
                    </div>
                  </form>
                </div>

                <div className="available_job_section"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="0.25"
                >
                  <motion.div
                    variants={textVariants("up", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="available_job_heading">
                      <h1>AVAILABLE JOBS</h1>
                    </div>
                  </motion.div>
                  <div className="available_job_content">
                    <div className="available_job_one_section">
                      <motion.div
                        variants={textVariants("up", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        {careerApplyForJob?.map((data, index) => {
                          return (
                            <div key={index} className="available_table_section">
                              <div className="mobile_hidden_table">
                                <div className="_section_available">
                                  <div className="_avail-d">
                                    <div className="_avail d-flex justify-content-between">
                                      <div>
                                        <p>Position</p>
                                        <h1>{data.position}</h1>
                                      </div>
                                      <div>
                                        <p>Department</p>
                                        <h1>{data.department}</h1>
                                      </div>
                                      <div>
                                        <p>Location</p>
                                        <h1>{data.location}</h1>
                                      </div>
                                      <div>
                                        <p>Job Type</p>
                                        <h1>{data.jobType}</h1>
                                      </div>
                                      <div
                                        className="know_more_btn_job"
                                        onClick={() =>
                                          handleClicked1(`panel${data.id}`)
                                        }
                                      >
                                        <button>
                                          {" "}
                                          {clicked1 === `panel${data.id}` ? (
                                            <BiMinus />
                                          ) : (
                                            <BsPlus />
                                          )}{" "}
                                        </button>
                                        <p className="m-0 text-center">
                                          Know More
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      className="know_more_btn_job mobile_"
                                      onClick={() =>
                                        handleClicked1(`panel${data.id}`)
                                      }
                                    >
                                      <button>
                                        {" "}
                                        {clicked1 === `panel${data.id}` ? (
                                          <BiMinus />
                                        ) : (
                                          <BsPlus />
                                        )}{" "}
                                      </button>
                                      <p className="text-center">Know More</p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* <div className="mobile_section_available">
                              <div className="mobile_avail">
                                <div>
                                  <p>Position</p>
                                  <h1>{data.position}</h1>
                                  <p>Department</p>
                                  <h1>{data.department}</h1>
                                  <p>Location</p>
                                  <h1>{data.location}</h1>
                                  <p>Job Type</p>
                                  <h1>{data.jobType}</h1>
                                </div>

                                <div
                                  className="know_more_btn_job"
                                  onClick={() => handleClicked1(`panel${data.id}`)}
                                >
                                  <button>
                                    {" "}
                                    {clicked1 === `panel${data.id}` ? <BiMinus /> : <BsPlus />}{" "}
                                  </button>
                                  <p className="text-center">Know More</p>
                                </div>
                              </div>
                            </div> */}

                              <div
                                className={
                                  clicked1 === `panel${data.id}`
                                    ? "showDiv"
                                    : "hideDivs"
                                }
                              >
                                <div className="available_section_know_more_section">
                                  <div className="available_know_more_heading">
                                    <h6>Job Description</h6>
                                  </div>
                                  <div className="available_know_more_content">
                                    <p>{data.jobDescription}</p>
                                  </div>
                                  <div className="available_skill_section">
                                    <div className="available_skill_section_heading">
                                      <h6>Skill</h6>
                                    </div>
                                    <div className="available_skill_section_content">
                                      <div className="row">
                                        {data.skills?.map((skill) => {
                                          return (
                                            <div
                                              key={skill.id}
                                              className=" col-lg-4 col-md-4 col-sm-12 col-12"
                                            >
                                              <ul>
                                                <li>{skill.skill}</li>
                                              </ul>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="available_qualification_section">
                                    <div className="available_qualification_section_heading">
                                      <h6>Qualifications and Experience</h6>
                                    </div>
                                    <div className="available_qualification_section_content">
                                      <div className="row">
                                        {data.qualifications?.map(
                                          (qualification) => {
                                            return (
                                              <div
                                                key={qualification.id}
                                                className=" col-lg-4 col-md-4 col-sm-12 col-12"
                                              >
                                                <ul>
                                                  <li>
                                                    {qualification.qualification}
                                                  </li>
                                                </ul>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="apply_for_job_section">
                                  <div className="apply_for_job_heading">
                                    <h6>Apply For This Job</h6>
                                  </div>

                                  <Form className="row">
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      <Form.Control
                                        type="text"
                                        placeholder="First name*"
                                      />
                                    </Form.Group>

                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                                      <Form.Control
                                        type="text"
                                        placeholder="Last name*"
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      <Form.Control
                                        type="email"
                                        placeholder="Email Id*"
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                                      {/* <Form.Control type="number" placeholder="Contact number*" /> */}
                                      <PhoneInput
                                        className="form-control"
                                        international
                                        placeholder="Contact number*"
                                        defaultCountry="IN"
                                        value={value}
                                        onChange={setValue}
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      {/* <Form.Control type="email" placeholder="Country*" /> */}
                                      <Form.Select
                                        className="form-control"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled value="">
                                          Country *
                                        </option>
                                        <option value="1">country</option>
                                      </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                                      <Form.Select
                                        className="form-control"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled value="">
                                          State *
                                        </option>
                                        <option value="1">state</option>
                                      </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      <Form.Select
                                        className="form-control"
                                        aria-label="Default select example"
                                      >
                                        <option selected disabled value="">
                                          City *
                                        </option>
                                        <option value="1">city</option>
                                      </Form.Select>
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                                      <Form.Control
                                        type="url"
                                        placeholder="Portfolio link"
                                      />
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      {/* <Form.Control type="file" placeholder="Portfolio Upload PDF,JPEG,PNG" /> */}
                                      <input
                                        type="file"
                                        className="d-none"
                                        id="portfolio"
                                        onChange={(e) =>
                                          setPortfolio(e.target.files[0])
                                        }
                                      />
                                      <label
                                        className="form-control portfolio_"
                                        htmlFor="portfolio"
                                      >
                                        <input
                                          type="text"
                                          value={portfolio?.name}
                                          placeholder="Portfolio Upload PDF,JPEG,PNG"
                                        />
                                        <img src={upload} />
                                      </label>
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5">
                                      {/* <Form.Control type="file" placeholder="Resume upload*" /> */}
                                      <input
                                        type="file"
                                        className="d-none"
                                        id="resume"
                                        onChange={(e) =>
                                          setResume(e.target.files[0])
                                        }
                                      />
                                      <label
                                        className="form-control resume_"
                                        htmlFor="resume"
                                      >
                                        <input
                                          type="text"
                                          value={resume?.name}
                                          placeholder="Resume upload*"
                                        />
                                        <img src={upload} />
                                      </label>
                                    </Form.Group>
                                    <Form.Group className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 pl-0">
                                      <Form.Control
                                        as="textarea"
                                        rows={8}
                                        cols={50}
                                        placeholder="Message/ Cover letter"
                                      />
                                    </Form.Group>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-5 cpatch_disply">
                                      <div className="captcha_section">
                                        {/* <ReCAPTCHA
                                  ref={recaptchaRef}
                                  size="invisible"
                                  sitekey="6Lf9mE8mAAAAAJrGoYOTTxQ1QEhTNbawJ1j1xJmA"
  
                                /> */}
                                        {/* <img src={Captcha} /> */}
                                      </div>
                                      <div
                                        className="banner_gallery_button"
                                      // style={{ marginTop: "2.9813289401427787vw" }}
                                      >
                                        <button classname="btn rounded-pill">
                                          Apply Now &nbsp; &nbsp;
                                          {/* <svg
                                          stroke="white"
                                          fill="none"
                                          stroke-width="2"
                                          viewBox="0 0 24 24"
                                          aria-hidden="true"
                                          height="1em"
                                          width="1em"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                                          ></path>
                                        </svg> */}
                                          <CTASvg />
                                        </button>
                                      </div>
                                    </div>
                                  </Form>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </motion.div>
                    </div>
                  </div>

                  <div className="term_email_hr">
                    <motion.div
                      variants={textVariants("up", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <p>
                        Don't find a position that suits you? Don't worry you can
                        send your CV in <a href="mailto:hr@multimetals.in"><span> hr@multimetals.in </span></a>
                      </p>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="testimonial_section"
            style={{ overflow: "hidden" }}
          // data-scroll-section
          >
            <div
              className={`testimonial_sec_bg  ${isSafari ? 'ios-safari' : ''}`}
              style={{
                backgroundImage: `url(${width > 767 ? BannerTest : mobileTestBanner
                  })`,
              }}
            >
              <div className="testimonial_working_main"
                data-scroll
                data-scroll-direction="vertical"
                data-scroll-class="appear"
                data-scroll-repeat="true"
                data-scroll-speed="0.25"
              >
                <motion.div
                  variants={textVariants("up", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
                >
                  <div className="testimonial_sec_heading">
                    <h1>WHY EMPLOYEES LOVE WORKING AT MULTIMETALS?</h1>
                  </div>
                </motion.div>
              </div>
              <motion.div
                variants={textVariants("up", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="testimonial_sec_slider"
                  data-scroll
                  data-scroll-direction="vertical"
                  data-scroll-class="appear"
                  data-scroll-repeat="true"
                  data-scroll-speed="0.25"
                >
                  <Swiper
                    pagination={pagination}
                    spaceBetween={40}
                    loop={true}
                    slidesPerView={2.25}
                    centeredSlides={true}
                    modules={[Pagination, Navigation, Autoplay]}
                    navigation={{
                      prevEl: ".left_arrow",
                      nextEl: ".right_arrow",
                    }}
                    className="mySwiper testimonial_slider"
                    breakpoints={{
                      30: {
                        slidesPerView: 1,
                      },
                      767.98: {
                        slidesPerView: 2,

                      },
                      1024: {
                        slidesPerView: 2.25,
                      },
                    }}
                  // onActiveIndexChange={(swiper) => {
                  //   var nextImg = next;
                  //   if (nextImg == data?.length) {
                  //     nextImg = 0;
                  //     console.log(next, data.length, nextImg);
                  //   } else {
                  //     nextImg = swiper.activeIndex + 1;
                  //     console.log(next, data.length);
                  //   }

                  //   setNext(nextImg);
                  // }}
                  >
                    {data?.map((Data) => {
                      return (
                        <SwiperSlide key={data.id}>
                          <div className="testimonial_slider_img">
                            <img src={Data.image} className="" height={75} />
                            <div className="testimonial_slider_border_card">
                              <div className="testimonial_sec_slider_content">
                                <p>{Data.content}</p>
                              </div>
                              <div className="testimonial_sec_slider_title">
                                <h1>{Data.name}</h1>
                                <p>{Data.position}</p>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* <div className="arrow_wrapper_">
                  <div className="swiper-button testi-swiper-button-prev">
                    <img src={leftArrow} alt="left arrow" className="left_arrow" />

                  </div>
                  <div className="swiper-button testi-swiper-button-next">
                    <img src={rightArrow} alt="right arrow" className="right_arrow" />
                  </div>
                </div> */}
                  <div className="arrow_wrapper">
                    <img src={leftArrow} alt="left arrow" className="left_arrow" />
                    <img src={rightArrow} alt="right arrow" className="right_arrow" />
                  </div>
                </div>
              </motion.div>
            </div>
          </section>

        </div>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Career;
