import React, { useEffect, useRef } from "react";
import PreLoader from "../../loader/preloader";
import { useWindowSize } from "react-use";
import { Link } from "react-router-dom";
import './thankyou.scss'
import { motion } from "framer-motion";
import { textVariants } from "../../Header/Motion";

import {
   images, submitButton,
} from "../../assets";

const ThankYou = () => {
   const { width } = useWindowSize();
   return (
      <>
         <section className="thankyou_bg">
            <div
               className="scroll_bg"
               data-scroll-container
               style={{
                  background: "#141414",
                  height: "100vh",
                  backgroundImage: `url(${images.thankyou.image})`,
                  backgroundSize: width > 600 ? "cover" : "cover",
                  overflow: "hidden",
                  backgroundPosition: "center",
                  backgroundAttachment: "fixed",

               }}
            >
               <motion.div
                  className="thankyou_content"
                  variants={textVariants("left", 0.2)}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.1 }}
                  transition={{ duration: 0.5 }}
               >
                  <h1 className="heading">
                     THANK YOU
                  </h1>
                  <p>
                     YOUR MESSAGE HAS BEEN RECEIVED, <br />
                     WE WILL UPDATE YOU SHORTLY.
                  </p>

                  <Link to="/">
                     <button>
                        Back to home <img src={submitButton} alt="backtohome" />
                     </button>
                  </Link>
               </motion.div>
            </div>
         </section>
      </>
   )
}

export default ThankYou
