import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { acrSpinner } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Acrspinner = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="ACR SPINNER"
          link2="/acr-spinner"
        /> */}
        <section id="scrolltop" className="acr_spinner_sec1">
          <div className="acr_spinner_container">
            <motion.div
              variants={textVariants("right", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="acr_content">
                <h2 className="acr_heading">ACR SPINNER</h2>
                <p className="acr_details">
                  Once the alloy has been solidified and shaped, it undergoes a
                  crucial process called ACR spinning. ACR (Automatic Core
                  Reduction) spinning is a specialized technique used to enhance
                  the mechanical properties and precision of the alloy. The spun
                  tube is subjected to controlled deformation, resulting in
                  improved strength, dimensional accuracy, and surface finish.
                  This process helps to refine the grain structure of the alloy,
                  optimizing its mechanical performance and making it suitable
                  for a wide range of applications.
                </p>
              </div>
            </motion.div>
            <div className="acr_image_wrapper">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={acrSpinner}
                  alt="Acr Spinner"
                  className="acr_img"
                  data-scroll
                  data-scroll-speed="1"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Acrspinner;
