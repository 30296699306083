import React, { useEffect } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Home from "../assets/image/img31.png";
import { TypeAnimation } from "react-type-animation";
import PreLoader from "../loader/preloader";
import Footer from "../Footer/Footer";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <section className="privacy_section">
        <div className="privacy_sec_bg">
          <div className="">
            <div className="privacy_breadcrumbs ">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img src={Home} width={20} />{" "}
                </Link>

                <Link to="/privacy">FAQ & Privacy Policy</Link>
              </Breadcrumbs>
            </div>

            <div className="privacy_heading">
              {/* <h1>FAQ & Privacy Policy</h1> */}
              <TypeAnimation
                sequence={[
                  "FAQ & Privacy Policy",
                  1000,
                  "FAQ & Privacy Policy",
                  1000,
                  "FAQ & Privacy Policy",
                  1000,
                  "FAQ & Privacy Policy",
                  1000,
                ]}
                speed={30}
                repeat={Infinity}
              />
            </div>

            <div className="privacy_sec_content">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy
                text of the printing and typesetting Lorem Ipsum has been the
                industry's standard dummy Lorem Ipsum is simply Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
                is simply dummy text Lorem Ipsum is simply dummy text of the
                printing and typesetting Lorem Ipsum has been the industry's
                standard dummy Lorem Ipsum is simply Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy Lorem Ipsum is simply dummy
                text Lorem Ipsum is simply dummy text of the printing and
                typesetting Lorem Ipsum has been the industry's standard dummy
                Lorem Ipsum is simply
                <br></br>
                <br></br>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                Lorem Ipsum is simply dummy text Lorem Ipsum is simply dummy
                text of the printing and typesetting Lorem Ipsum has been the
                industry's standard dummy Lorem Ipsum is simply Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy Lorem Ipsum
                is simply dummy text Lorem Ipsum is simply dummy text of the
                printing and typesetting Lorem Ipsum has been the industry's
                standard dummy Lorem Ipsum is simply Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy Lorem Ipsum is simply dummy
                text Lorem Ipsum is simply dummy text of the printing and
                typesetting Lorem Ipsum has been the industry's standard dummy
                Lorem Ipsum is simply
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="footer_area"
        data-scroll-section
        style={{
          overflowY: "hidden",
        }}
      >
        <Footer />
      </section>

    </>
  );
};

export default Privacy;
