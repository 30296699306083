import React, { useEffect, useRef, useState } from 'react';
import CountUp from "react-countup";

const AnimatedCountUp = ({ end }) => {

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const [entry] = entries;
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target); // Stop observing once it's visible
                }
            },
            { threshold: 0.5 } // Adjust the threshold as needed
        );

        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current); // Clean up observer on unmount
            }
        };
    }, []);

    return (
        <div ref={ref}>
            {isVisible && <CountUp end={end} />}
        </div>
    )
}

export default AnimatedCountUp