import { React, useEffect, useRef, useState } from "react";
import "./qualityoverview.scss";
import Commonbanner from "../../Common Banner/CommonBanner";
import {
  icon1,
  images,
  leftArrow,
  overviewBanner,
  overviewIcon,
  overviewIcon1,
  overviewIcon2,
  overviewIcon3,
  quality_assurance_main,
  customer_approval_main,
  testing_facilities_main,
  quality_assurance_small,
  rightArrow,
  testingBanner,
} from "../../assets";
import customerRing from "../../assets/image/overview/CustomerApprovalsRings.png";
import testingsmall from "../../assets/image/overview/TestingFacilitiesTube.png";
import { useWindowSize } from "react-use";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
import { Link } from "react-router-dom";
const imagesLoaded = require("imagesloaded");

const Overview = () => {
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  const pagination = {
    clickable: false,
    renderBullet: function (index, className) {
      let pageNumber = index + 1;
      if (pageNumber < 10) {
        pageNumber = "0" + pageNumber;
      }
      return '<span class="' + className + '">' + pageNumber + "</span>";
    },
  };

  const productData = [
    {
      id: 1,
      img: images.quality1.image,
      alt: images.quality1.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    },
    {
      id: 2,
      img: images.quality2.image,
      alt: images.quality2.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    },
    {
      id: 3,
      img: images.quality3.image,
      alt: images.quality3.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    },
    {
      id: 4,
      img: images.quality4.image,
      alt: images.quality4.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    },
    {
      id: 5,
      img: images.quality5.image,
      alt: images.quality5.alt,
      // details: `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`,
    },
  ];

  let slidesDuplicate = [...productData];

  const productList = slidesDuplicate.map((item, i) => (
    <SwiperSlide key={slidesDuplicate[i].id}>
      <div className="product_content">
        <img
          className="prod_img"
          height={780}
          src={item.img}
          alt={item.alt}
          // onClick={() => {
          //   handleQualitySlideIndex(i);
          // }}
        />
        <p className="product_details">{item.details}</p>
      </div>
    </SwiperSlide>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        ref={scrollRef}
        //
        data-scroll-container
        style={{
          background: "#141414",
          backgroundImage: `url(${overviewBanner})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="overview_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            // image={overviewBanner}
            linkText="QUALITY"
            link="/quality"
            linkText1="QUALITY OVERVIEW"
            link1="/quality-overview"
            headingtext="QUALITY OVERVIEW"
            scrollId="#quality"
          />
          <div className="icon_container">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
              whileHover={{ scale: 1.1 }}
            >
              <img src={overviewIcon} alt="icon" className="icon_img" />
            </motion.div>
          </div>
        </section>

        <section id="quality" className="overview_sec2" data-scroll-section>
          <div className="content_container">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">QUALITY OVERVIEW</h2>
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                One of our key strengths lies in our state-of-the-art testing
                facilities, which are equipped with advanced technologies. These
                facilities enable us to conduct comprehensive physical,
                electrical, and mechanical tests on our products. By subjecting
                our products to these rigorous tests, we can guarantee their
                performance, durability, and safety. Our commitment to quality
                and compliance is further reinforced by our adherence to
                international quality standards, ensuring that our products
                consistently meet the highest industry benchmarks
              </p>
            </motion.div>
          </div>

          <div className="product_container">
            <div className="row product_row">
              <div className="col-md-6 order-md-1 product_col">
                <div className="col_content">
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={overviewIcon1}
                      alt="icon"
                      className="proudct_icon"
                    />
                  </motion.div>
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="heading">QUALITY ASSURANCE</h2>
                  </motion.div>
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="text">
                      Experience the epitome of quality with Multimetals where
                      with our unwavering commitment to excellence, we have
                      earned prestigious accolades including the AS9100D
                      certificate, ISO 9001:2015 certification, PED
                      Certification, self-certification AD 2000 Merkblatt W6/2
                      from TUV Nord, and NABL certification as per ISO/IEC
                      17025:2017. These esteemed certifications validate our
                      rigorous processes, advanced methodologies, and
                      cutting-edge testing facilities that uphold the highest
                      industry standards.
                    </p>
                  </motion.div>
                  {width > 767 ? (
                    // <div className="banner_gallery_button">
                    //   <a href="javscript:void(0)">
                    //     <button classname="btn rounded-pill">
                    //       Learn more &nbsp; &nbsp;
                    //       <svg
                    //         stroke="white"
                    //         fill="none"
                    //         stroke-width="2"
                    //         viewBox="0 0 24 24"
                    //         aria-hidden="true"
                    //         height="1em"
                    //         width="1em"
                    //         xmlns="http://www.w3.org/2000/svg"
                    //       >
                    //         <path
                    //           stroke-linecap="round"
                    //           stroke-linejoin="round"
                    //           d="M17 8l4 4m0 0l-4 4m4-4H3"
                    //         ></path>
                    //       </svg>
                    //     </button>

                    //   </a>
                    // </div>
                    <div className="cta_wrapper hero-btn-d">
                      <motion.div
                        className="cta"
                        variants={textVariants("left", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Link to="/certifications">
                          <Button>
                            Learn more
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </Button>
                        </Link>
                      </motion.div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6  order-md-2 product_col">
                <div className="product_img_wrapper">
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={quality_assurance_main}
                      alt="Quality Assurance"
                      className="product_img"
                    />
                  </motion.div>
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={quality_assurance_small}
                      alt="dumy image"
                      className="product_small_img"
                    />
                  </motion.div>
                  {width <= 767 ? (
                    <motion.div
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="banner_gallery_button">
                        <Link to="/certifications">
                          <button classname="btn rounded-pill">
                            Learn more &nbsp; &nbsp;
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </Link>
                      </div>
                    </motion.div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6  order-md-4 product_col">
                <div className="col_content">
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={overviewIcon2}
                      alt="icon"
                      className="proudct_icon1"
                    />
                  </motion.div>
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="heading">CUSTOMER APPROVALS</h2>
                  </motion.div>
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="text">
                      Multimetals customer approvals validate our unwavering
                      commitment to delivering superior quality products,
                      exceptional service, and tailored solutions. esteemed
                      clients such as Petrobras, Abu Dhabi Marine Operating
                      Company, Samsung, Kuwait National Petroleum Company, Saudi
                      Aramco, and Sabic, validate our unwavering commitment to
                      delivering superior quality products, exceptional service,
                      and tailored solutions. These industry leaders exemplify
                      our dedication to upholding stringent quality standards
                      and adhering to rigorous quality control protocols. Their
                      trust and satisfaction inspire us to continually exceed
                      expectations and strengthen our relationships.
                    </p>
                  </motion.div>
                  {width > 767 ? (
                    <div className="cta_wrapper hero-btn-d">
                      <motion.div
                        className="cta"
                        variants={textVariants("right", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Link to="/vendor-approval">
                          <Button>
                            Learn more
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </Button>
                        </Link>
                      </motion.div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6  order-md-3 product_col">
                <div className="product_img_wrapper">
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={customer_approval_main}
                      alt="Quality Assurance"
                      className="product_img"
                    />
                  </motion.div>{" "}
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={customerRing}
                      alt="dumy image"
                      className="product_small_img1"
                    />
                  </motion.div>
                  {width <= 767 ? (
                    <motion.div
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="banner_gallery_button">
                        <Link to="/vendor-approval">
                          <button classname="btn rounded-pill">
                            Learn more &nbsp; &nbsp;
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </Link>
                      </div>
                    </motion.div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 order-md-5 product_col">
                <div className="col_content">
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={overviewIcon3}
                      alt="icon"
                      className="proudct_icon2"
                    />
                  </motion.div>
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <h2 className="heading">TESTING FACILITIES</h2>
                  </motion.div>
                  <motion.div
                    variants={textVariants("left", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <p className="text">
                      Discover the cutting-edge capabilities of our advanced
                      testing facilities at Multimetals. With state-of-the-art
                      equipment and expert technicians, we ensure rigorous
                      quality checks and thorough evaluation of our products.
                      Our testing facilities enable us to deliver exceptional
                      precision, reliability, and compliance with international
                      standards. Trust in our commitment to excellence as we
                      employ the latest testing methodologies to guarantee the
                      highest level of performance and durability.
                    </p>
                  </motion.div>
                  {width > 767 ? (
                    <div className="cta_wrapper hero-btn-d">
                      <motion.div
                        className="cta"
                        variants={textVariants("left", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <Link to="/testing-facilities">
                          <Button>
                            Learn more
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </Button>
                        </Link>
                      </motion.div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6 order-last product_col">
                <div className="product_img_wrapper">
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={testing_facilities_main}
                      alt="dummy image"
                      className="product_img"
                    />
                  </motion.div>
                  <motion.div
                    variants={textVariants("right", 0.2)}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src={testingsmall}
                      alt="dumy image"
                      className="product_small_img2"
                    />
                  </motion.div>
                  {width <= 767 ? (
                    <motion.div
                      variants={textVariants("left", 0.2)}
                      initial="hidden"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="banner_gallery_button">
                        <Link to="/testing-facilities">
                          <button classname="btn rounded-pill">
                            Learn more &nbsp; &nbsp;
                            <svg
                              stroke="white"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M17 8l4 4m0 0l-4 4m4-4H3"
                              ></path>
                            </svg>
                          </button>
                        </Link>
                      </div>
                    </motion.div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="content_container2">
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">
                Ensuring Unparalleled Quality: Our Commitment to Excellence
              </h2>
            </motion.div>
            <motion.div
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                Multimetals unwavering commitment to uncompromising quality is
                validated by our comprehensive quality assurance processes,
                advanced testing facilities, and trusted customer approvals,
                ensuring superior products and exceptional service.
              </p>
            </motion.div>
          </div>

          <div className="product_slider">
            <Swiper
              autoHeight={true}
              loop={true}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={32}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={{
                prevEl: ".left_arrow",
                nextEl: ".right_arrow",
              }}
              pagination={pagination}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  spaceBetween: 93,
                  autoHeight: true,
                },
                768: {
                  spaceBetween: 230,
                },
              }}
            >
              {productList}
            </Swiper>
            <div className="arrow_wrapper">
              <img src={leftArrow} alt="left arrow" className="left_arrow" />
              <img src={rightArrow} alt="right arrow" className="right_arrow" />
            </div>
            {/* <Swiper
              loop={true}
              speed={500}
              autoPlay={true}
              effect={"coverflow"}
              grabCursor={false}
              allowTouchMove={false}
              centeredSlides={true}
              slidesPerView={"auto"}
              navigation={true}
              modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
              className="gallery_swiper"
              pagination={pagination}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 500,
                modifier: 1,
                slideShadows: false,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
            >
              {productList}
            </Swiper> */}
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Overview;
