import { images, productInnerBanner, specialityVideo, tubebg } from "../assets";
import _ from "lodash";
export const ProductCategoriesArr = [
  {
    id: 1,
    type: "image",
    backgroundImg: tubebg,
    title: "Tube & Pipes",
    slug: "tube-and-pipes",
    description:
      "At Multimetals, we specialize in the manufacturing of high-quality tubes and pipes that cater to a wide range of industries. Our tubes and pipes are crafted with precision and adhere to the highest standards of quality and performance. With a commitment to innovation and advanced manufacturing techniques, we offer a diverse range of tubes and pipes that meet the unique requirements of our customers and deliver exceptional performance in various applications.",
  },
  {
    id: 2,
    type: "video",
    backgroundImg: specialityVideo,
    title: "Speciality & Finned Tubes",
    slug: "speciality-and-finned-tubes",
    description:
      "Multimetals takes pride in producing specialized and finned tubes that are tailored to meet the specific needs of various industries. Our specialty and finned tubes offer enhanced heat transfer capabilities and superior performance in applications such as heat exchangers, air conditioning systems, and refrigeration units. With our advanced manufacturing processes and attention to detail, we deliver high-quality and reliable tubes that excel in efficiency and durability. Whether it's for commercial, industrial, or residential purposes, our specialty and finned tubes are designed to optimize performance and deliver exceptional results.",
  },
  {
    id: 3,
    type: "image",
    backgroundImg: productInnerBanner,
    title: "Cast, Extruded & Drawn Semis",
    slug: "cast-extruded-and-drawn-semis",
    description:
      "Multimetals offers a diverse range of casts /7 semis including copper, copper nickel, nickel silver, and brass rods, brass hollows, aluminum bronze rods, and phosphorus bronze rods. Our state-of-the-art manufacturing processes ensure precision and consistency in the production of these products, which find extensive applications in industries such as automotive, electrical, aerospace, and more. With a commitment to excellence, we deliver products that meet the highest quality standards and exhibit exceptional strength, durability, and performance. Trust Multimetals for reliable and top-quality cast, extruded, and drawn semis that meet your specific requirements with unmatched precision and reliability.",
  },
];

export const productsArr = [
  {
    id: 1,
    category_id: 1,
    title: "Copper",
    slug: "copper",
    application_area:
      "High capacity Power Plants, Ship Building & Ship Repairs,Heat Exchangers and Condensers, Distiller Tubes, Evaporators, Ferrules",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 1,
        title: "ETP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["BS 2871 C101", "IS 2501 Cu ETP", "ASTM B 188 C11000"],
        image: images.copper_etp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Electrical conductor </li> <li>> Electrical switchgear </li> <li>> Bus Bar </li> <li>> Electrical contact </li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H2S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 1,
        title: "FRTP Copper",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["IS 2501 Cu FRTP", "IS 191 Cu FRTP", "BS 2871 C102"],
        image: images.copper_frtp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Electrical conductor </li> <li>> Electrical switchgear </li> <li>> Bus Bar </li> <li>> Electrical contact </li> <li>> Spring clips </li> <li>> Terminals and Connectors </li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H2S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 4,
        category_id: 1,
        title: "DHP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2871 C106",
          "ASTM B111 C12200",
          "B 68 C12200",
          "B 75 C12200",
          "JIS H 3300 C1220",
          "EN 12451 CW024A",
          "EN 12449 CW024A",
        ],
        image: images.copper_dhp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Air conditioner </li> <li>> Chiller </li> <li>> Refrigeration tubing and their fitting </li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H2S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 1,
        title: "DLP Copper",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASMB SB 75 C12000"],
        image: images.copper_dlp.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Air conditioner </li> <li>> Chiller </li> <li>> Refrigeration tubing and their fitting </li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_tubes_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts less than 500 ppm </li> <li>> Chloride less than 10 ppm </li> <li>> H2S or ammonia nil </li> <li>> Organic fats nil </li> <li>> Suspended solids less than 5 ppm </li> </ul>",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 2,
    category_id: 1,
    title: "Copper Nickel",
    slug: "copper-nickel",
    application_area:
      "Chillers, Heat Exchangers & Condensers, Power Plants, Refineries, Desalination Plants, Ship Building & Ship Repairs, Defense",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_nickel_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 2,
        title: "95/5 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 111	C70400"],
        image: images.copper_nickel95.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Chillers </li> <li>> Heat Exchangers & Condensers </li> <li>> Power Plants </li> <li>> Refineries </li> <li>> Desalination Plants </li> <li>> Ship Building & Ship Repairs</li> <li>> Defense</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel95_specsheet.image,
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 2,
        title: "90/10 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM B 111	C 70600",
          "BS 2871 Part 3 CN 102",
          "EN 12451 CuNi10 Fe 1Mn",
          "NFA51 102	CuNi10 Fe1Mn",
          "JIS H 3300	C 7060",
          "EEMUA Pub 144 1987	C7060X",
          "NES 779 Part 3 90/10 CuNi",
        ],
        image: images.copper_nickel90.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Chillers </li> <li>> Heat Exchangers & Condensers </li> <li>> Power Plants </li> <li>> Refineries </li> <li>> Desalination Plants </li> <li>> Ship Building & Ship Repairs</li> <li>> Defense</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel90_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts from 1500 to 3000 ppm </li> <li>> Suspended solids more than 25 ppm </li> <li>> H2S or Ammonia less than 1 ppm </li></ul>",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 4,
        category_id: 2,
        title: "70/30 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM B 111	C 71500",
          "BS 2871 part 3	CN 107",
          "EN 12451	CuNi 30 Fe 1 Mn",
          "NFA 51 102	CuNi 30 Fe 1 Mn",
          "JIS H 3300	C 7150",
        ],
        image: images.copper_nickel70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> High capacity Power Plants </li> <li>> Ship Building & Ship Repairs </li> <li>> Heat Exchangers and Condensers </li> <li>> Distiller Tubes </li> <li>> Evaporators </li> <li>> Ferrules</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel70_specsheet.image,
        spec_three_title: "Guide to selection of tube materials",
        spec_three_desc:
          "<ul> <li>> Dissolved salts more than 10,000 ppm </li> <li>> Presence of abrasive suspended solids </li> <li>> Polluted with H2S and Ammonia </li></ul>",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 2,
        title: "CuNifer",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTMB 111	C71640"],
        image: images.copper_nickel_cunifer.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Chillers </li> <li>> Heat Exchangers & Condensers </li> <li>> Power Plants </li> <li>> Refineries </li> <li>> Desalination Plants </li> <li>> Ship Building & Ship Repairs</li> <li>> Defense</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.copper_nickel95_specsheet.image,
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 3,
    category_id: 1,
    title: "Plain Brasses",
    slug: "plain-brasses",
    application_area:
      "Sugar Industry, Plumbing, Furniture, General Engineering, Ammunition, Bore Hole Tubes, Ammunition, Architectural Grill",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brasses_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 3,
        title: "90/10 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 111	C 22000", "BS 2874 CZ - 112", "EN 12451	Cu Zn10"],
        image: images.brasses_tube90.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Defense </li> <li>> Bushes </li> <li>> Driving Bands </li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 3,
        title: "Red Brass 85/15",
        slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B135 C23000"],
        image: images.brasses_tube_red85.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Red brass is used in those applications where corrosion resistance and low cost is important. </li> <li>> Plumbing </li> <li>> Fixture </li> <li>> Electronic components </li> <li>> Valves and Fittings </li> <li>> Gears and Pulleys </li> <li>> Gears and Pulleys,</li> <li>> Marine component </li> <li>> Automotive parts </li> <li>> Musical instruments.  </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 4,
        category_id: 3,
        title: "70/30 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM B 135	C 26000",
          "BS 2871 Part 3	CZ - 126",
          "EN 12451	CuZn30As",
          "NFA 51 102	CuZn30",
          "JIS H 3300	C 2600",
          "AS 1572	26130",
        ],
        image: images.brasses_tube70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Sugar Industry </li> <li>> Plumbing </li> <li>> Furniture </li> <li>> General Engineering </li> <li>> Ammunition </li> <li>> Bore Hole Tubes</li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: images.brass_tube70_specsheet.image,
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 3,
        title: "65/35 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B135 C27000"],
        image: images.brasses_tube65.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Chemical handling </li> <li>> Plumbing </li> <li>> Utility water </li> <li>> Architectural</li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 3,
        title: "63/37 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 135	C 27200", "JIS H 3300	C 2700", "DIN 17671	CuZn37"],
        image: images.brasses_tube63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> General Engineering </li> <li>> Ammunition </li> <li>> Bore Hole Tubes </li> <li>> Architectural Grill </li> </ul>",
        spec_two_title: "Specsheets",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 3,
        title: "60/40 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B135 C28000"],
        image: images.brasses_tube60.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Architectural panels </li> <li>> Large architectural trim </li> <li>> Door frame </li> <li>> Decorative hardware </li> <li>> Condenser and heat exchanger application </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 3,
        title: "Naval Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 21	C 46400", "BS 2874	CZ - 112", "DIN 17665	CuZn40Sn"],
        image: images.brasses_tube_naval.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Marine </li> <li>> Defense </li> <li>> Bushes </li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 3,
        title: "80/20 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B135 C24000"],
        image: images.brasses_tube80.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Decorative trim applications </li> <li>> Lock </li> <li>> Gears </li> <li>> Bearings </li> <li>> Valves </li> <li>> Braces</li> <li>> Brackets</li> <li>> Base plate</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 4,
    category_id: 1,
    title: "Admiralty Brass",
    slug: "admiralty-brass",
    application_area:
      "Heat Exchangers, Refineries Power Plants, Desalination Plants, Ship Building & Ship Repairs, Condensers, Ferrules, Distillers",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "ASTM B 111	C 44300",
      "BS 2871 Part 3	CZ - 111",
      "EN 12451	CuZn28 Sn1As",
      "NFA 51 102	CuZn29Sn1",
      "JIS H 3300	C 4430",
    ],
    image: images.ad_brass.image,
    listingImage: images.ad_brasses_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Heat Exchangers </li> <li>> Refineries Power Plants </li> <li>> Desalination Plants </li> <li>> Ship Building & Ship Repairs </li> <li>> Condensers </li> <li>> Ferrules</li> <li>> Distillers </li> </ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.admiralty_brass_specsheet.image,
    spec_three_title: "Guide to selection of tube materials",
    spec_three_desc:
      "<ul> <li>> Dissolved salts less than 2000 ppm </li> <li>> Chloride less than 20 ppm </li> <li>> H2S and Ammonia is less than 1 ppm </li> <li>> Suspended solids less than 25 ppm </li> <li>> For temperature more than 200 degree C and less than 450 degree C. </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 5,
    category_id: 1,
    title: "Aluminium Brass",
    slug: "aluminium-brass",
    application_area:
      "Heat Exchangers, Refineries Power Plants, Desalination Plants, Ship Building & Ship Repairs, Condensers, Ferrules, Distillers",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: [
      "ASTM B 111	C 68700",
      "BS 2871 PART 3	CZ - 110",
      "EN 12451	CuZN20 AL2As",
      "NFA 51 102	CuZn22 Al2",
      "JIS H - 3300	C 6870",
    ],
    image: images.al_brass.image,
    listingImage: images.al_brasses_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Heat Exchangers </li> <li>> Refineries Power Plants </li> <li>> Desalination Plants </li> <li>> Ship Building & Ship Repairs </li> <li>> Condensers </li> <li>> Ferrules </li> <li>> Distillers </li> </ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.aluminium_brass_specsheet.image,
    spec_three_title: "Guide to selection of tube materials",
    spec_three_desc:
      "<ul> <li>> Dissolved salts more than 2000 ppm </li> <li>> Chloride less than 20 ppm </li> <li>> H2S and Ammonia is less than 1 ppm </li> <li>> Suspended solids less than 25 ppm </li> <li>> For temperature more than 200 degree C and less than 450 degree C </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 6,
    category_id: 1,
    title: "Copper Arsenic Aluminium Bronze",
    slug: "copper-aresenic-aluminium-bronze",
    application_area:
      "Heat Exchangers in Chemical Industry, Desalination Plants, Refineries, Ship Building & Ship Repairs.",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 111	C60800", "EN 12451	CuAl5As", "EN 12451	CW200G"],
    image: images.al_bronze.image,
    listingImage: images.al_bronze_listing.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Heat Exchangers in Chemical Industry </li> <li>> Desalination Plants </li> <li>> Refineries </li> <li>> Ship Building & Ship Repairs. </li> </ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.aluminium_bronze_specsheet.image,
    spec_three_title: "",
    spec_three_desc: "",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 7,
    category_id: 2,
    title: "Finned tube as per ASTM B 359",
    slug: "finned-tubes",
    application_area:
      "Heat Exchangers, Condensers and Coolers, Condenser and Heat Exchange Unit",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 359"],
    image: images.finned_tube.image,
    listingImage: images.finned_tube_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Heat Exchanger </li> <li>> Condensers and Coolers </li> <li>> Condenser and Heat Exchange Unit </li> </ul>",
    spec_two_title: "Specsheets",
    spec_two_desc: images.finned_tube_specsheet.image,
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> Copper </li> <li>> Copper Nickel 95/5, 90/10, 70/30 </li> <li>> Admiralty Brass </li> <li>> Aluminum Brass </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 8,
    category_id: 2,
    title: "U Bend Tubes",
    slug: "u-bend-tubes",
    application_area: "Condensers, Evaporators, Radiators and Heat Exchangers",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 395"],
    image: images.u_bend_tube.image,
    listingImage: images.u_bend_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Condensers </li> <li>> Evaporators </li> <li>> Radiators and Heat Exchangers </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> Copper </li> <li>> Copper Nickel 95/5, 90/10, 70/30 </li> <li>> Admiralty Brass </li> <li>> Aluminium Brass </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 9,
    category_id: 2,
    title: "Machined Components",
    slug: "machined-components",
    application_area:
      "Driving Band Blanks for Defense Applications, Marine Industry, Synchronizer Rings for Automobile, Bushes",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B135", "ASTM B75", "MIL-B-18907", "MIL-B-20292"],
    image: images.machined_component.image,
    listingImage: images.machined_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Driving Band Blanks for Defense Applications </li> <li>> Marine Industry </li> <li>> Synchronizer Rings for Automobile </li> <li>> Bushes </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloys",
    spec_three_desc:
      "<ul> <li>> CuNi5 </li> <li>> Naval Brass </li> <li>> Copper </li> <li>> Manganese Bronze </li> <li>> Gilding Metal (C22000) </li> <li>> Copper Alloy (C12200) </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 10,
    category_id: 3,
    title: "Copper Rods",
    slug: "copper-rods",
    application_area:
      "Electrical conductor, Electrical switchgear, Bus Bar, Electrical contact, Spring clips, Terminals and Connectors",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 10,
        title: "ETP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B187 C11000", "BS 2874  C 101", "IS613 CuETP"],
        image: images.etp_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Electrical conductor </li> <li>> Electrical switchgear </li> <li>> Bus Bar </li> <li>> Electrical contact </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 10,
        title: "FRTP Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B187 C11000", "BS 2874 C102"],
        image: images.frtp_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Electrical conductor </li> <li>> Electrical switchgear </li> <li>> Bus Bar </li> <li>> Electrical contact </li> <li>> Spring clips </li> <li>> Terminals and Connectors </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 4,
        category_id: 10,
        title: "DPA Copper",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["CMS 23", "BS", "ASTM", "VSM", "NBN"],
        image: images.dpa_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Heat Exchange Equipment including Condenser Tubes and Tube Plates using Clean, Fresh Water </li> <li>> Steam Locomotives Fireboxes, Stay Bolts and Rivets </li> <li>> Lubrication and Other Pipes </li> <li>>  Stills Vafs, Kettles, Autoclaves and General Coppersmitthing where Welding Brazing is involved. </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 10,
        title: "Tellurium Copper Rods",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B301", "BS 2874"],
        image: images.tellurium_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Electric Connectors </li> <li>> Switchgear Components </li> <li>> Gas Cutting Nozzles </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 11,
    category_id: 3,
    title: "Copper Nickel Rods",
    slug: "copper-nickel-rods",
    application_area:
      "	Marine Hardware, Flanges, Oil & Gas Applications, Welding Rings",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.copper_nickel_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 11,
        title: "90/10 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["NES 780 Pt. 2", "MIL C 15726 F", "ASTM B 151", "EN 12163"],
        image: images.copper_nickel90_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Marine Hardware </li> <li>> Flanges </li> <li>> Oil & Gas Applications </li> <li>> Welding Rings </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 11,
        title: "70/30 CuNi",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["NES 780 Pt. 2", "MIL C 15726 F", "ASTM B 151", "EN 12163"],
        image: images.copper_nickel70_rod.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Marine Hardware </li> <li>> Flanges </li> <li>> Oil & Gas Applications </li> <li>> Welding Rings </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 12,
    category_id: 3,
    title: "Brass Rods",
    slug: "brass-rods",
    application_area:
      "	Condenser Components, Marine hardware, Welding Rods, Rivet, Valves, Piston, Rods.",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brass_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 3,
        category_id: 12,
        title: "90/10 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_rod90.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 12,
        title: "Red Brass 85/15",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 927 C23000"],
        image: images.brass_rod_red.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Red brass is used in those applications where corrosion resistance and low cost is important. </li> <li>> Plumbing </li> <li>> Fixture </li> <li>> Electronic components </li> <li>> Valves and Fittings </li> <li>> Gears and Pulleys </li> <li>> Gears and Pulleys,</li> <li>> Marine component </li> <li>> Automotive parts </li> <li>> Musical instruments.  </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 4,
        category_id: 12,
        title: "70/30 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_rod70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 12,
        title: "65/35 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 927 C27000"],
        image: images.brass_rod65.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Used in deep drawing component </li> <li>> Ammunition casings </li> <li>> Zippers </li> <li>> Plumbing </li> <li>>  Hose couplings </li> <li>> Valves and electrical plugs and sockets </li> <li>> Musical instruments such as horns and bell</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 12,
        title: "63/37 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_rod63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 12,
        title: "60/40 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 927 C28000"],
        image: images.brass_rod60.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Valve stem </li> <li>> Plumbing fixture such as faucets and valves </li> <li>> Electrical components like switches and connector </li> <li>> Industrial machinery part </li> <li>> Door hardware </li> <li>> Marine hardware</li> <li>> Artistic sculptures</li> <li>> Architectural accents</li> <li>> Jewellery components</li> <li>> Musical instruments </li> <li>> Furniture trim </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 12,
        title: "Naval Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_rod_naval.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 5,
        category_id: 12,
        title: "80/20 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: ["ASTM B 927 C24000"],
        image: images.brass_rod80.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Decorative trim applications </li> <li>> Lock </li> <li>> Gears </li> <li>> Bearings </li> <li>> Valves </li> <li>> Braces</li>  <li>> Brackets</li> <li>> Base plate</li> </ul>",
        spec_two_title: "",
        spec_two_desc: images.nickelsilver.image,
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 6,
        category_id: 12,
        title: "95/5 Brass",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_rod95.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Condenser Components </li> <li>> Marine hardware </li> <li>> Welding Rods </li> <li>> Rivet </li> <li>> Ammunition </li> <li>> Valves</li> <li>> Piston</li> <li>> Rods</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 13,
    category_id: 3,
    title: "Brass Hollows",
    slug: "brass-hollows",
    application_area:
      "Sugar industry, plumbing, furniture, hand pumps, ammunition, and general engineering ",
    // description:"",
    // standards:["",""],
    image: images.nickelsilver.image,
    listingImage: images.brass_hollow_rod_liting.image,
    // spec_one_title:"",
    // spec_one_desc:"",
    // spec_two_title:"",
    // spec_two_desc:"",
    // spec_three_title:"",
    // spec_three_desc:"",
    have_child: true,

    childs: [
      {
        id: 2,
        category_id: 13,
        title: "70/30 Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_hollow70.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Sugar industry </li> <li>> Plumbing </li> <li>> Furniture </li> <li>> Hand pumps </li> <li>> Ammunition </li> <li>> General engineering</li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 13,
        title: "63/37 Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "BS 2874",
          "ASTM B 21",
          "B 135",
          "B 43",
          "DIN 17665",
          "JIS H3250",
        ],
        image: images.brass_hollow63.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Architectural </li> <li>> Skirting </li> <li>> Fasteners </li> <li>>  Plumbing accessories </li> <li>> Snap buttons </li> <li>> Deep drawn parts</li> <li>> Lamp fixtures </li> <li>> Rivets </li> <li>> Hinges </li> <li>> Flashlight cell's button </li> <li>> Electrical industry component </li> <li>> Zippers </li> <li>> Stamp parts </li> <li>> Eyelet fastners </li> <li>> Connectors </li> </ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
      {
        id: 3,
        category_id: 13,
        title: "Admiralty Brass Hollow",
        // slug: "",
        // description:
        //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
        standards: [
          "ASTM B111 C44300",
          "BS CZ111",
          "JIS H3100 C4430",
          "DIN 2.047",
          "ISO CuZn28Sn1",
          " IS 1545 CuZn29Sn1As",
        ],
        image: images.ad_brass_hollow.image,
        spec_one_title: "Product Applications areas",
        spec_one_desc:
          "<ul> <li>> Evaporator Tubing </li> <li>> Heat exchanger Tubing </li> <li>> Condenser Tubing </li> <li>> Oil Well pump liner </li> <li>> Bourdon tubes etc. </li></ul>",
        spec_two_title: "",
        spec_two_desc: "",
        spec_three_title: "",
        spec_three_desc: "",
        have_child: false,
        parent_id: 1,
      },
    ],
  },
  {
    id: 14,
    category_id: 3,
    title: "Nickel Silver Rods",
    slug: "nickel-silver-rods",
    application_area:
      "Guards, Pins, nuts, Automobile parts, Springs, Zippers, Musical Instruments, Spectacles, Ammunition",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B151", "EN 12163"],
    image: images.nickel_silver_rod.image,
    listingImage: images.nickel_silver_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Guards </li> <li>> Pins </li> <li>> nuts </li> <li>> Automobile parts </li> <li>> Springs </li> <li>> Zippers </li> <li>> Musical Instruments </li> <li>> Spectacles </li> <li>> Ammunition </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloy",
    spec_three_desc:
      "<ul> <li>> N.S.104 </li> <li>> C74500 </li> <li>> N.S.105 </li> <li>> C75200 </li> <li>> N.S.106 </li> <li>> C75200</li> <li>> N.S.107 </li> <li>> C76400 </li> <li>> CW403J </li> <li>> CW409J</li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 15,
    category_id: 3,
    title: "Aluminum Bronze Rod",
    slug: "aluminium-bronze-rod",
    application_area:
      "High Pressure Valve, Gears, Valve Marine Pumps, Marine Hardware Bearing cages, Bushings",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["ASTM B 105", "EN 12163", "BS 2874"],
    image: images.Al_bronze_rod.image,
    listingImage: images.al_bronze_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> High Pressure Valve </li> <li>> Gears </li> <li>> Valve Marine Pumps </li> <li>> Marine Hardware Bearing cages </li> <li>> Bushings </li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloy",
    spec_three_desc:
      "<ul> <li>> CA 103 </li> <li>> CA 104 </li> <li>> C 63000 </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
  {
    id: 16,
    category_id: 3,
    title: "Phosphorus Bronze Rods",
    slug: "phosphorus-bronze-rods",
    application_area:
      "Washers, Fasteners, Switch Parts, Electrical Connectors, Electronic & Precision Equipment Parts, Welding Rods, Springs",
    // description:
    //   "Phosphor bronze is an alloy made primarily of copper, tin, and phosphorus. It is known for its high strength, durability, and resistance to corrosion. The amount of tin and phosphorus in the alloy can vary depending on the specific application and desired properties, but typically it contains between 0.5% and 11% tin and 0.01% to 0.5% phosphorus.",
    standards: ["EN 12166", "ASTM B 139"],
    image: images.Phosphorous_bronze_rod.image,
    listingImage: images.phosphorous_bronze_liting.image,
    spec_one_title: "Product Applications areas",
    spec_one_desc:
      "<ul> <li>> Washers </li> <li>> Fasteners </li> <li>> Switch Parts </li> <li>> Electrical Connectors </li> <li>> Electronic & Precision Equipment Parts </li> <li>> Welding Rods</li> <li>> Springs</li> </ul>",
    spec_two_title: "",
    spec_two_desc: "",
    spec_three_title: "Alloy",
    spec_three_desc:
      "<ul> <li>> P.B.4% CuSn4 </li> <li>> P.B.5% CuSn5 </li> <li>> P.B.6% CuSn6 </li> <li>> P.B.8% CuSn8 </li> <li>> P.B.10% </li> </ul>",
    have_child: false,
    parent_id: null,

    childs: [],
  },
];

export const findAllProducts = (category_slug = null) => {
  if (category_slug) {
    const category = findCategoryWhere({ slug: category_slug });
    console.log({ category });
    // return _.find(datas, { 'category': category_slug });
    return _.filter(productsArr, { category_id: category[0]?.id });
  } else {
    return productsArr;
  }
};

export const getHeaderProducts = (category_id = null) => {
  const category = findCategoryWhere({ id: category_id });
  // return _.find(datas, { 'category': category_slug });
  return _.filter(productsArr, { category_id: category_id }).map((item) => {
    return {
      title: item.title,
      slug: `/products/${category?.[0]?.slug}/${item.slug}`,
    };
  });
};

export const findProductBySlug = (slug) => {
  return _.find(productsArr, { slug: slug });
};

export const findCategoryBySlug = (slug) => {
  return _.find(ProductCategoriesArr, { slug: slug });
};

export const findCategoryWhere = (filter = {}) => {
  return _.filter(ProductCategoriesArr, filter);
};
export const findProductWhere = (filter = {}) => {
  return _.filter(productsArr, filter);
};
