import React, { useEffect, useRef } from 'react';

const AnimatedText = ({ text }) => {
    const textRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Element is in viewport, start the animation
                    textRef.current.classList.add('animate-text');
                    observer.unobserve(entry.target);
                }
            });
        });

        if (textRef.current) {
            observer.observe(textRef.current);
        }

        return () => {
            if (textRef.current) {
                observer.unobserve(textRef.current);
            }
        };
    }, []);

    return (
        <>
            <h1 className="text-outline" data-text={text} ref={textRef}>
                {text}
            </h1>

        </>
    )
}

export default AnimatedText