import { React, useEffect, useRef, useState } from "react";
import "./packaging&dispatch.scss";
import Commonbanner from "../../Common Banner/CommonBanner";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import {
  first_scroll_short,
  first_scroll_long,
  images,
  leftArrow,
  packagingBanner,
  rightArrow,
} from "../../assets";
import { useWindowSize } from "react-use";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const mainImage = [
  { id: 1, img: images.slide1.image, alt: images.slide1.alt },
  { id: 2, img: images.slide2.image, alt: images.slide2.alt },
  { id: 3, img: images.slide3.image, alt: images.slide3.alt },
  { id: 4, img: images.slide4.image, alt: images.slide4.alt },
  { id: 5, img: images.slide5.image, alt: images.slide5.alt },
  { id: 6, img: images.slide6.image, alt: images.slide6.alt },
];

const thumnailImage = [
  {
    id: 1,
    img: images.thumnail1.image,
    alt: images.thumnail1.alt,
    mbImg: images.thumnail1.image,
  },
  {
    id: 2,
    img: images.thumnail2.image,
    alt: images.thumnail2.alt,
    mbImg: images.thumnail2.image,
  },
  {
    id: 3,
    img: images.thumnail3.image,
    alt: images.thumnail3.alt,
    mbImg: images.thumnail3.image,
  },
  {
    id: 4,
    img: images.thumnail4.image,
    alt: images.thumnail4.alt,
    mbImg: images.thumnail4.image,
  },
  {
    id: 5,
    img: images.thumnail5.image,
    alt: images.thumnail5.alt,
    mbImg: images.thumnail5.image,
  },
  {
    id: 6,
    img: images.thumnail6.image,
    alt: images.thumnail6.alt,
    mbImg: images.thumnail6.image,
  },
];

const Packaging = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  const mainList = mainImage.map((item, i) => (
    <SwiperSlide key={i}>
      <img height={717} src={item.img} alt={item.alt} className="main_img" />
    </SwiperSlide>
  ));

  const thumbnailList = thumnailImage.map((item, i) => (
    <SwiperSlide key={i}>
      <img
        height={202}
        src={width > 767 ? item.img : item.mbImg}
        alt={item.alt}
        className="thumnail_img"
      />
    </SwiperSlide>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${packagingBanner})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="banner_area packaging_sec"
          style={{ overflow: "hidden", zIndex: 2 }}
          data-scroll-section
        >
          <Commonbanner
            // image={packagingBanner}
            linkText="QUALITY"
            link="/quality"
            linkText1="PACKAGING & DISPATCH"
            link1="/packaging-&-dispatch"
            headingtext="Packaging & Dispatch"
            scrollId="#packaging"
          />
        </section>
        <section
          id="packaging"
          className="packaging_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="row packaging_row">
            <div
              className="col-md-6 packaging_col"
              data-scroll
              data-scroll-speed="0.5"
            >
              <motion.div
                variants={textVariants("left", 0.2, 2.5)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <div>
                  <h2 className="heading">PACKAGING & DISPATCH</h2>
                </div>
                <p className="text">
                  Multimetals understand the importance of delivering our
                  products to our customers in pristine condition. That's why we
                  take utmost care in our packaging and dispatch processes to
                  ensure that our customers receive products of the highest
                  quality without any damage.
                </p>

                <div>
                  <h4>Robust Packaging:</h4>
                  <p>
                    {" "}
                    We employ robust packaging materials and techniques to
                    safeguard our products during transportation. Each product
                    is carefully packaged using protective measures such as
                    Bubble wrapping, water proofing, use of silica gels, and
                    secure containers to minimize the risk of damage.
                  </p>
                  <h4>Quality Assurance:</h4>
                  <p>
                    {" "}
                    Our diligent quality assurance team conducts thorough
                    inspections to ensure that every product meets our stringent
                    quality standards before it is packaged. This ensures that
                    only the finest products are dispatched to our customers.
                  </p>
                  <h4>International Shipping Expertise: </h4>
                  <p>
                    {" "}
                    Catering to customers in far-off continents and remote
                    locations, we have developed expertise in international
                    shipping. We understand the challenges associated with
                    long-distance transportation and employ measures to mitigate
                    risks and protect the integrity of our products.
                  </p>
                  <h4>Customized Packaging Solutions: </h4>
                  <p>
                    {" "}
                    We understand that different products require specific
                    packaging solutions. Our experienced team designs customized
                    packaging solutions tailored to the specific requirements of
                    each product, ensuring optimal protection during transit.
                  </p>
                  <h4>Efficient Dispatch Process:</h4>
                  <p>
                    {" "}
                    Our efficient dispatch process ensures that orders are
                    promptly processed and dispatched, minimizing delays and
                    ensuring timely delivery. We work closely with reputable
                    logistics partners to ensure reliable and efficient
                    transportation of our products.
                  </p>
                  <h4>Customer Satisfaction: </h4>
                  <p>
                    {" "}
                    Customer satisfaction is at the core of our packaging and
                    dispatch processes. We go the extra mile to ensure that our
                    customers receive their products in excellent condition,
                    allowing them to utilize our products without any hassle or
                    inconvenience.
                    <br />
                    <br />
                    We are committed to delivering products of exceptional
                    quality to our customers worldwide. Through meticulous
                    packaging and dispatch measures, we ensure that our products
                    reach our customers in pristine condition, regardless of
                    their location.
                  </p>
                </div>
              </motion.div>
            </div>

            <div className="col-md-6 packaging_col">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="img_wrapper">
                  {width > 767 ? (
                    <img
                      data-scroll
                      data-scroll-speed="-1"
                      src={first_scroll_long}
                      alt="first_scroll_long"
                      className="packaging_img"
                    />
                  ) : null}
                  <img
                    data-scroll
                    data-scroll-speed="2"
                    src={first_scroll_short}
                    alt="first_scroll_short"
                    className="pack_img"
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section
          className="packaging_sec2"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="product_wrapper">
            <div className="main_slider_wrap">
              <Swiper
                loop={true}
                spaceBetween={10}
                // breakpointsBase='window'
                navigation={{
                  prevEl: ".left_arrow",
                  nextEl: ".right_arrow",
                }}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper_image"
              >
                {mainList}
              </Swiper>
              {width > 992 ? (
                <div className="arrow_wrapper">
                  <img
                    src={leftArrow}
                    alt="left arrow"
                    className="left_arrow"
                  />
                  <img
                    src={rightArrow}
                    alt="right arrow"
                    className="right_arrow"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="thumnail_container">
              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={39}
                slidesPerView={6}
                freeMode={true}
                watchSlidesProgress={true}
                breakpointsBase="window"
                // loopedSlides={thumbnailList.length}
                // allowTouchMove={false}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper_thumnail"
                breakpoints={{
                  0: {
                    slidesPerView: 3.5,
                    spaceBetween: 16.6,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 25,
                  },
                  1280: {
                    slidesPerView: 6,
                    // spaceBetween: 39,
                    spaceBetween: 38.79,
                  },
                }}
              >
                {thumbnailList}
              </Swiper>
              {width < 992 ? (
                <div className="arrow_wrapper">
                  <img
                    src={leftArrow}
                    alt="left arrow"
                    className="left_arrow"
                  />
                  <img
                    src={rightArrow}
                    alt="right arrow"
                    className="right_arrow"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Packaging;
