import React, { useEffect, useRef, useState } from "react";
import "../Clients/Indigenious.scss";
// import metalMap from '../../assets/Clients/metal map.png'
import { useWindowSize } from "react-use";
import { images } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { textVariants } from "../../Header/Motion";
import LocomotiveScroll from "locomotive-scroll";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const indigeniousArr = [
  {
    id: 1,
    img: images.clinetlogo_colored_indi_1.image,
    imgBlack: images.clinetlogo_bw_indi_1.image,
    alt: images.clinetlogo_colored_indi_1.alt,
  },
  {
    id: 2,
    img: images.clinetlogo_colored_indi_2.image,
    imgBlack: images.clinetlogo_bw_indi_2.image,
    alt: images.clinetlogo_colored_indi_2.alt,
  },
  {
    id: 3,
    img: images.clinetlogo_colored_indi_3.image,
    imgBlack: images.clinetlogo_bw_indi_3.image,
    alt: images.clinetlogo_colored_indi_3.alt,
  },
  {
    id: 4,
    img: images.clinetlogo_colored_indi_4.image,
    imgBlack: images.clinetlogo_bw_indi_4.image,
    alt: images.clinetlogo_colored_indi_4.alt,
  },
  {
    id: 5,
    img: images.clinetlogo_colored_indi_5.image,
    imgBlack: images.clinetlogo_bw_indi_5.image,
    alt: images.clinetlogo_colored_indi_5.alt,
  },
  {
    id: 6,
    img: images.clinetlogo_colored_indi_6.image,
    imgBlack: images.clinetlogo_bw_indi_6.image,
    alt: images.clinetlogo_colored_indi_6.alt,
  },
  {
    id: 7,
    img: images.clinetlogo_colored_indi_7.image,
    imgBlack: images.clinetlogo_bw_indi_7.image,
    alt: images.clinetlogo_colored_indi_7.alt,
  },
  {
    id: 8,
    img: images.clinetlogo_colored_indi_8.image,
    imgBlack: images.clinetlogo_bw_indi_8.image,
    alt: images.clinetlogo_colored_indi_8.alt,
  },
  {
    id: 9,
    img: images.clinetlogo_colored_indi_9.image,
    imgBlack: images.clinetlogo_bw_indi_9.image,
    alt: images.clinetlogo_colored_indi_9.alt,
  },
  {
    id: 10,
    img: images.clinetlogo_colored_indi_10.image,
    imgBlack: images.clinetlogo_bw_indi_10.image,
    alt: images.clinetlogo_colored_indi_10.alt,
  },
  {
    id: 11,
    img: images.clinetlogo_colored_indi_11.image,
    imgBlack: images.clinetlogo_bw_indi_11.image,
    alt: images.clinetlogo_colored_indi_11.alt,
  },
  {
    id: 12,
    img: images.clinetlogo_colored_indi_12.image,
    imgBlack: images.clinetlogo_bw_indi_12.image,
    alt: images.clinetlogo_colored_indi_12.alt,
  },
  {
    id: 13,
    img: images.clinetlogo_colored_indi_13.image,
    imgBlack: images.clinetlogo_bw_indi_13.image,
    alt: images.clinetlogo_colored_indi_13.alt,
  },
  {
    id: 14,
    img: images.clinetlogo_colored_indi_14.image,
    imgBlack: images.clinetlogo_bw_indi_14.image,
    alt: images.clinetlogo_colored_indi_14.alt,
  },
  {
    id: 15,
    img: images.clinetlogo_colored_indi_15.image,
    imgBlack: images.clinetlogo_bw_indi_15.image,
    alt: images.clinetlogo_colored_indi_15.alt,
  },
  {
    id: 16,
    img: images.clinetlogo_colored_indi_16.image,
    imgBlack: images.clinetlogo_bw_indi_16.image,
    alt: images.clinetlogo_colored_indi_16.alt,
  },
  {
    id: 17,
    img: images.clinetlogo_colored_indi_17.image,
    imgBlack: images.clinetlogo_bw_indi_17.image,
    alt: images.clinetlogo_colored_indi_17.alt,
  },
  {
    id: 18,
    img: images.clinetlogo_colored_indi_18.image,
    imgBlack: images.clinetlogo_bw_indi_18.image,
    alt: images.clinetlogo_colored_indi_18.alt,
  },
  {
    id: 19,
    img: images.clinetlogo_colored_indi_19.image,
    imgBlack: images.clinetlogo_bw_indi_19.image,
    alt: images.clinetlogo_colored_indi_19.alt,
  },
  {
    id: 20,
    img: images.clinetlogo_colored_indi_20.image,
    imgBlack: images.clinetlogo_bw_indi_20.image,
    alt: images.clinetlogo_colored_indi_20.alt,
  },
  {
    id: 21,
    img: images.clinetlogo_colored_indi_21.image,
    imgBlack: images.clinetlogo_bw_indi_21.image,
    alt: images.clinetlogo_colored_indi_21.alt,
  },
];

const Indigenious = () => {
  const { width } = useWindowSize();
  const scrollRef = useRef(null);

  const indigeniousClient = indigeniousArr.map((item) => (
    <div className="col-md-2 col-sm-4 col-3" style={{ columnGap: "2rem" }}>
      <motion.div
        variants={textVariants("up", 0.2)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.5 }}
        className="clients-card"
      >
        <img src={item.imgBlack} alt={item.alt} className="blackWhite" />
        <img src={item.img} className="color" alt={item.alt} />
      </motion.div>
    </div>
  ));

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.indiBg.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="indegenious_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div
            className="indigenious_bg"
            // style={{ backgroundImage: `url(${images.indiBg.image})` }}
          >
            <div className="gallery_breadcrumbs indigenious_breadcrumbs">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link>Client</Link>
                <Link to="/indigenous">Indigenous</Link>
              </Breadcrumbs>
            </div>
            <div className="indigenious_banner_wrapper">
              <div className="my_container">
                <div className="row indigenious_row_sec1">
                  <div className="col-sm-3 col-md-3">
                    <div className="indigenious_heading">
                      <motion.h1
                        variants={textVariants("left", 0.2)}
                        initial="hidden"
                        whileInView="show"
                        viewport={{ once: true, amount: 0.1 }}
                        transition={{ duration: 0.5 }}
                      >
                        INDIGENOUS
                      </motion.h1>
                    </div>
                  </div>
                  <div className="col-sm-9 col-md-9">
                    <motion.div
                      variants={textVariants("right", 0.2)}
                      initial="hidden"
                      className="metalMap"
                      whileInView="show"
                      viewport={{ once: true, amount: 0.1 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src={images.metalMap.image}
                        alt={images.metalMap.alt}
                      />
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="scroll-down-d indigenious_scroll"
              style={{ cursor: "pointer", zIndex: "3" }}
            >
              {/* <p className="text-center">Scroll for more</p> */}
              <div className="spinner scroll-down">
                <a href="#indigenous" data-scroll-to className="animate"></a>
              </div>
            </div>
          </div>
        </section>

        <section
          id="indigenous"
          className="indegenious_sec2"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <div className="indigenious-content-section">
            <motion.h3
              variants={textVariants("up", 0.2)}
              initial="hidden"
              className="metalMap"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              Domestic Excellence and <br /> Trusted Partnerships
            </motion.h3>
            <motion.p
              variants={textVariants("up", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              Multimetals takes delight in its adherence to local clients in
              India, serving a diverse range of industries with excellence. We
              have established ourselves as a trusted partner for Nuclear and
              Thermal Power Plants, Petroleum Refineries, Ship Building and
              Repairs, Heavy engineering Industries and Defence, and many more
              sectors. Our esteemed clients include eminent names such as
              Reliance, NTPC, BHEL, Indian Oil Corporation, NPCIL, Thermax,
              Mazagon Dock Shipbuilders ltd., and many others. This widespread
              recognition and collaboration with industry leaders are a
              testament to our commitment to delivering top-notch products and
              solutions that meet the highest standards of quality and
              reliability. At Multimetals, we are honored to serve our esteemed
              clients and look forward to building lasting partnerships based on
              trust, excellence, and mutual success.
            </motion.p>

            <div className="row indigenious_row">{indigeniousClient}</div>
          </div>
        </section>
        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Indigenious;
