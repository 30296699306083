import React, { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";
import "./awards&recognition.scss";
import { images, leftArrow, rightArrow } from "../../assets";
import Commonbanner from "../../Common Banner/CommonBanner";
import { useWindowSize } from "react-use";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import { useRef } from "react";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Awards = () => {
  const { width } = useWindowSize();
  const [clicked, setclicked] = useState(0);
  const [swiperHeight, setSwiperHeight] = useState(0);
  const [certificateHeight, setCertificateHeight] = useState(0);
  const scrollRef = useRef(null);
  const swiperRef = useRef(null);
  const certificateRef = useRef(null);

  const awardsData = [
    {
      id: 1,
      img: images.awards1.image,
      alt: images.awards1.alt,
      title: " EEPCINDIA",
      details: "Star Performer",
      awardDate: "2017-18",
    },
    {
      id: 2,
      img: images.awards3.image,
      alt: images.awards1.alt,
      title: " Rajasthan State Export Award",
      details: "Highest Growth",
      awardDate: "2021",
      // title: "Rajasthan State Export Award",
      // details: "Export Excellence",
      // awardDate: "1995-96",
    },
    {
      id: 3,
      img: images.awards5.image,
      alt: images.awards1.alt,
      title: "EEPCINDIA",
      details: "Star Performer",
      awardDate: "2016-17",
    },
    {
      id: 4,
      img: images.awards6.image,
      alt: images.awards1.alt,
      title: "Rajasthan State Export Award",
      details: "Highest Exports",
      awardDate: "2021",
    },
    // {
    //   id: 5,
    //   img: images.awards5.image,
    //   alt: images.awards1.alt,
    //   title: "EEPCINDIA",
    //   details: "Export Excellence",
    //   awardDate: "2002-03",
    // },
    {
      id: 6,
      img: images.awards4.image,
      alt: images.awards1.alt,
      title: "Rajasthan State Export Award",
      details: "Export Excellence",
      awardDate: "2003-04",
    },
    // {
    //   id: 7,
    //   img: images.awards5.image,
    //   alt: images.awards1.alt,
    //   title: "EEPCINDIA",
    //   details: "Star Performer",
    //   awardDate: "2014-15",
    // },
    {
      id: 8,
      img: images.awards2.image,
      alt: images.awards1.alt,
      title: "Rajasthan State Export Award",
      details: "Export Excellence",
      awardDate: "1995-96",
    },
  ];

  const certificateData = [
    {
      id: 1,
      img: images.awardscertificate1.image,
      alt: images.awardscertificate1.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 2,
      img: images.awardscertificate2.image,
      alt: images.awardscertificate2.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 3,
      img: images.awardscertificate3.image,
      alt: images.awardscertificate3.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 4,
      img: images.awardscertificate5.image,
      alt: images.awardscertificate5.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 5,
      img: images.awardscertificate6.image,
      alt: images.awardscertificate6.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 6,
      img: images.awardscertificate7.image,
      alt: images.awardscertificate7.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 7,
      img: images.awardscertificate8.image,
      alt: images.awardscertificate8.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 8,
      img: images.awardscertificate9.image,
      alt: images.awardscertificate9.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 9,
      img: images.awardscertificate10.image,
      alt: images.awardscertificate10.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 10,
      img: images.awardscertificate11.image,
      alt: images.awardscertificate11.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
    {
      id: 11,
      img: images.awardscertificate12.image,
      alt: images.awardscertificate12.alt,
      // title: "Lorem Ipsum",
      // desc: "Lorem Ipsum is simply dummy Lorem Ipsum is",
    },
  ];

  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: certificateData.id,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const toggle = (i) => {
    if (clicked === i) {
      return setclicked(null);
    } else {
      setclicked(i);
    }
  };

  const pagination = {
    clickable: false,
    renderBullet: function (index, className) {
      let pageNumber = index + 1;
      if (pageNumber < 10) {
        pageNumber = "0" + pageNumber;
      }
      return '<span class="' + className + '">' + pageNumber + "</span>";
    },
  };

  const awardsList = awardsData.map((item, i) => (
    <SwiperSlide key={awardsData[i].id}>
      <img
        className="award_img"
        // width={647}
        // height={width > 600 ? 828 : "auto"}
        src={item.img}
        alt={item.alt}
      />
      <div className="awards_content">
        <h2 className="awards_title">{item.title}</h2>
        <p className="awards_details">{item.details}</p>
        <p className="awards_details">{item.awardDate}</p>
      </div>
    </SwiperSlide>
  ));

  const certificateList = certificateData.map((item, i) => (
    <div
      className="col-md-4 certificate_col"
      key={certificateData[i].id}
      onClick={() => openLightboxOnSlide(item.id)}
    >
      <div>
        <div className="certificate_content">
          <img
            width={515}
            height={width > 600 ? 355 : "auto"}
            src={item.img}
            alt={item.alt}
            className="certificate_img"
          />
          <div className="certificate_details">
            <h3
              className={`subheading ${clicked === i ? "active" : ""}`}
              onClick={() => toggle(i)}
            >
              {item.title}
            </h3>
            {clicked === i ? <p className="description">{item.desc}</p> : null}
          </div>
        </div>
      </div>
    </div>
  ));

  useEffect(() => {
    const timer = setTimeout(() => {
      if (swiperRef.current) {
        const swiperBoxHeight = swiperRef.current.offsetHeight;
        setSwiperHeight(swiperBoxHeight);
      }
      if (certificateRef.current) {
        const certificateBoxHeight = certificateRef.current.offsetHeight;
        setCertificateHeight(certificateBoxHeight);
      }
    }, 1000); // Check height every second

    return () => clearTimeout(timer);
  }, []);
  // console.log("swiper", swiperHeight);
  // console.log("certificate", certificateHeight);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    if (swiperRef.current) {
      const swiperBoxHeight = swiperRef.current.offsetHeight;
      setSwiperHeight(swiperBoxHeight);
      // console.log("swiper", swiperBoxHeight);
    }

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          backgroundImage: `url(${images.awardsBanner.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <section
          id="scrolltop"
          className="awards_sec1"
          style={{ overflow: "hidden" }}
          data-scroll-section
        >
          <Commonbanner
            // image={awardsBanner}
            linkText="ABOUT US"
            link="/about"
            linkText1="AWARDS & RECOGNITION"
            link1="/awards-&-recognition"
            headingtext="Awards & Recognition"
            scrollId="#awards"
          />
        </section>

        <section
          id="awards"
          className="awards_sec2"
          style={{
            height: "100%",
            minHeight: swiperHeight,
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div className="awards_slider">
            <Swiper
              ref={swiperRef}
              autoHeight={true}
              loop={true}
              // roundLengths={true}
              effect={"coverflow"}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={"auto"}
              spaceBetween={32}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 300,
                modifier: 1,
                slideShadows: false,
              }}
              navigation={{
                prevEl: ".left_arrow",
                nextEl: ".right_arrow",
              }}
              pagination={pagination}
              // autoplay={{
              //   delay: 3000,
              //   disableOnInteraction: false,
              // }}
              modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
              className="mySwiper"
              breakpoints={{
                0: {
                  spaceBetween: 93,
                  autoHeight: true,
                },
                768: {
                  spaceBetween: 230,
                },
              }}
            >
              {awardsList}
            </Swiper>
            <div className="arrow_wrapper">
              <img src={leftArrow} alt="left arrow" className="left_arrow" />
              <img src={rightArrow} alt="right arrow" className="right_arrow" />
            </div>
          </div>
        </section>

        <section
          className="awards_sec3"
          style={{
            height: "100%",
            minHeight: certificateHeight,
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div className="content_container">
            <motion.div
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">AWARDS & RECOGNITION</h2>
            </motion.div>
            <motion.div
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <p className="text">
                We take immense pride in its distinguished track record of
                receiving 19+ export excellence awards, which serve as a
                testament to the dedication and hard work of our team in
                consistently delivering high-quality products and services.
                <br />
                <br />
                We have been honored with Certificate of Export Excellence by
                the Engineering Export Promotion Council (EEPC) multiple times
                year on year, highlighting our exceptional export performance
                and commitment to quality. Additionally, We are proud to have
                been named among the top 500 Indian manufacturing SMBs, a
                testament to our consistent growth and industry leadership.{" "}
                <br />
                <br />
                Furthermore, Multimetals has been recognized as a Star Performer
                in EEPC India's Export Excellence Award for the Northern Region.
                This prestigious award acknowledges our outstanding achievements
                and consistent efforts in promoting exports and upholding
                excellence in our operations.
                <br />
                <br />
                In addition to these, Multimetals has also been honored with
                numerous other awards, certificates, and trophies that further
                exemplify our commitment to excellence and industry leadership.
              </p>
            </motion.div>
          </div>

          <div className="certificate_container">
            <motion.div
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="heading">CERTIFICATES</h2>
            </motion.div>
            <div className="row certificate_row">{certificateList}</div>
          </div>
        </section>

        <section
          className="footer_area"
          data-scroll-section
          style={{ height: "100%", overflowY: "hidden" }}
        >
          <Footer />
        </section>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        sources={[
          images.awardscertificate1.image,
          images.awardscertificate2.image,
          images.awardscertificate3.image,
          images.awardscertificate5.image,
          images.awardscertificate6.image,
          images.awardscertificate7.image,
          images.awardscertificate8.image,
          images.awardscertificate9.image,
          images.awardscertificate10.image,
          images.awardscertificate11.image,
          images.awardscertificate12.image,
        ]}
      />
    </>
  );
};
export default Awards;
