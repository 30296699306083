import React from "react";
import { images } from "../../assets";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import "./financial.scss";
import FinanceTabs from "./FinanceTabs";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import { useRef } from "react";
import { useEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";
import { useWindowSize } from "react-use";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Financial = () => {
  const { width } = useWindowSize();
  const scrollRef = useRef(null);
  const accordionContainer = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      // console.log("Body height changed:", entries[0].target.clientHeight);
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);

    // scroll.on("call", (func) => {
    //   console.log("block1 triggered", func);
    //   if (sticky == null) {
    //     setSticky(func);
    //   } else {
    //     setSticky(null);
    //   }
    // });
  }, [accordionContainer]);
  return (
    <>
      <PreLoader />
      <div
        ref={scrollRef}
        data-scroll-container
        style={{
          background: "#141414",
          backgroundImage: `url(${images.financialbanner.image})`,
          backgroundSize: width > 600 ? "cover" : "contain",
          overflow: "hidden",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        {/* <section data-scroll-sticky>
          <HeaderNew stickyProp={sticky} />
        </section> */}
        <section
          id="scrolltop"
          className="financial_sec1"
          style={{
            overflow: "hidden",
          }}
          data-scroll-section
        >
          <div
            className="gallery_bg"
            style={{
              background: "transparent",
            }}
            data-scroll
            data-scroll-direction="vertical"
            data-scroll-class="appear"
            data-scroll-repeat="true"
          >
            <div className="gallery_breadcrumbs">
              <Breadcrumbs separator=">" aria-label="breadcrumb">
                <Link to="/">
                  <img
                    src={images.homeicon.image}
                    alt={images.homeicon.alt}
                    loading="lazy"
                    width={20}
                  />{" "}
                </Link>
                <Link to="/about">About US</Link>
                <Link to="/financial">Financial</Link>
              </Breadcrumbs>
            </div>
            <motion.div
              variants={textVariants("left", 0.2)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.5 }}
            >
              <div className="financial_heading">
                <h1>Financial</h1>
              </div>
            </motion.div>
            <div className="scroll-down-d">
              <div className="spinner scroll-down">
                <a href="#financial" data-scroll-to className="animate"></a>
              </div>
            </div>
            {/* <div className="img_wrapper">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={images.copperpipe.image}
                  alt={images.copperpipe.alt}
                  className="banner_copper_img"
                />
              </motion.div>
            </div> */}
          </div>
        </section>

        <section
          // data-scroll
          // data-scroll-repeat
          // data-scroll-call="sticky"
          id="financial"
          className="financial_sec2"
          style={{ height: "100%", overflow: "hidden" }}
          data-scroll-section
        >
          <div className="financial_tabs_bg">
            <FinanceTabs />
          </div>
        </section>

        <section
          className="footer_area"
          data-scroll-section
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Financial;
