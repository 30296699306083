import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { extrusion } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Extrusion = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="EXTRUSION"
          link2="/extrusion"
        /> */}
        <section id="scrolltop" className="extrusion_sec1">
          <div className="row extrusion_container">
            <div className="col-md-5 extrusion_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="extrusion_heading">EXTRUSION</h2>
                <p className="extrusion_details">
                  Once the tubes have undergone the draw bench process, they may
                  be further processed through extrusion. Extrusion is a
                  manufacturing technique that involves forcing the tubes
                  through a shaped die to create complex cross-sectional
                  profiles. This process allows for the production of tubes with
                  customized shapes, sizes, and surface finishes. The extrusion
                  process enhances the versatility and functionality of the
                  tubes, making them suitable for a wide range of applications
                  that require specific geometries and configurations.(type of
                  machine: direct oil hydraulic extrusion press)(it pushes
                  through a die and then takes the desired shape for the first
                  time throughout the product/also called as mother tube)(also
                  produces in rod shapes) in rod shape, as well as complex
                  cross-sectional profiles and sections.
                </p>
              </motion.div>
            </div>
            <div className="col-md-7 extrusion_content">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={extrusion}
                  alt="Extrusion"
                  className="extrusion_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Extrusion;
