import React from 'react'

const CTASvg = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.4197916666666666vw" height="0.7979166666666667vw" viewBox="0 0 27.339 15.792">
                <g id="Group_3225" data-name="Group 3225" transform="translate(0 15.577) rotate(-90)">
                    <path id="Path_922" data-name="Path 922" d="M0,0S6.8,3.238,7.528,7.677" transform="translate(0 19.582)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_923" data-name="Path 923" d="M7.736,0S.03,4.534,0,7.647" transform="translate(7.588 19.582)" fill="none" stroke="#fff" stroke-width="1" />
                    <path id="Path_924" data-name="Path 924" d="M0,0V27.2" transform="translate(7.589)" fill="none" stroke="#fff" stroke-width="1" />
                </g>
            </svg>
            
        </>
    )
}

export default CTASvg