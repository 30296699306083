import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { pilger } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Pilger = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="PILGER"
          link2="/pilger"
        /> */}
        <section id="scrolltop" className="pilger_sec1">
          <div className="pilger_container">
            <div className="pilger_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="pilger_heading">PILGER</h2>
                <p className="pilger_details">
                  After the ACR spinning process, the alloy is further processed
                  using a pilger machine. The pilgering process involves
                  reducing the diameter and wall thickness of the alloy tubes
                  while increasing their length. This is achieved by passing the
                  tubes through a set of specially designed dies that exert both
                  radial and axial forces on the material. Through this process,
                  the tubes undergo controlled deformation, resulting in
                  improved mechanical properties and dimensional precision. The
                  pilgering process ensures that the tubes meet the desired
                  specifications and exhibit superior strength and durability.
                </p>
              </motion.div>
            </div>
            <div className="pilger_image_wrapper">
              <motion.div
                variants={textVariants("right", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={pilger} alt="pilger" className="pilger_img" />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Pilger;
