import { React, useEffect, useRef } from "react";
import "./manufacturingprocessinner.scss";
import { straightener } from "../../assets";
import LocomotiveScroll from "locomotive-scroll";
import { textVariants } from "../../Header/Motion";
import { motion } from "framer-motion";
import Footer from "../../Footer/Footer";
import PreLoader from "../../loader/preloader";
const imagesLoaded = require("imagesloaded");

const Straightener = () => {
  const scrollRef = useRef(null);

  useEffect(() => {
    let scrollContainer = document.querySelector("[data-scroll-container]");
    const scroll = new LocomotiveScroll({
      el: scrollRef.current,
      smooth: true,
      getSpeed: true,
      getDirection: true,
      multiplier: 0.4, // Adjust the scroll speed here
    });

    scroll.update(); // Manually update the scroll height

    imagesLoaded(scrollContainer, { background: true }, function () {
      scroll.update();
    });
    const resizeObserver = new ResizeObserver((entries) => {
      scroll.update();
    });

    // start observing a DOM node
    resizeObserver.observe(document.body);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <PreLoader />
      <div
        data-scroll-container
        ref={scrollRef}
        style={{
          background: "#141414",
          overflow: "hidden",
        }}
      >
        {/* <Commonbanner2
          linkText="ABOUT US"
          link="/about"
          linkText1="MANUFACTURING PROCESS"
          link1="/manufacturing-process"
          linkText2="STRAIGHTENER"
          link2="/straightener"
        /> */}
        <section id="scrolltop" className="straightener_sec1">
          <div className="straightener_container">
            <div className="straightener_content">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="straightener_heading">STRAIGHTENER</h2>
                <p className="straightener_details">
                  After the extrusion process, the tubes are passed through a
                  straightener machine. The straightener helps to remove any
                  residual deformations or bends in the tubes, ensuring their
                  straightness and uniformity. This process is crucial for
                  achieving precise dimensions and surface characteristics. The
                  straightener machine applies controlled forces to straighten
                  the tubes and improve their overall appearance and structural
                  integrity. By enhancing the straightness of the tubes, the
                  straightener ensures that they meet the required
                  specifications and can be easily integrated into various
                  applications.
                </p>
              </motion.div>
            </div>
            <div className="straightener_image_wrapper">
              <motion.div
                variants={textVariants("left", 0.2)}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  src={straightener}
                  alt="straightener"
                  className="straightener_img"
                />
              </motion.div>
            </div>
          </div>
        </section>
        <section
          className="footer_area"
          style={{
            overflowY: "hidden",
          }}
        >
          <Footer />
        </section>
      </div>
    </>
  );
};

export default Straightener;
